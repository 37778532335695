<template>
  <vue-final-modal
    overlay-class="os-modal-overlay"
    class="os-modal-container"
    content-class="os-modal-content os-modal-article"
    :content-transition="options.contentTransition"
    :swipe-to-close="options.swipeToClose"
    :esc-to-close="true"
    @closed="$emit('update:model-value', false)"
  >
    <template #default>
      <div class="os-modal-wrapper">
        <div
          v-if="Object.keys(article).length"
          class="os-column-start tw-w-full tw-gap-8 2xl:tw-gap-6"
        >
          <h2 class="tw-text-2xl md:tw-text-[30px] tw-font-semibold tw-text-black">{{ article.name }}</h2>

          <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-w-full tw-gap-8">
            <div class="tw-max-w-[250px]">
              <img
                :src="`${BASE_URL}/${article.picture}` || DefaultImg"
                alt="article preview"
                class="tw-w-full"
              >
            </div>

            <div class="tw-grid tw-grid-cols-2 min-[460px]:tw-grid-cols-3 md:tw-grid-cols-2 tw-justify-between tw-gap-6 md:tw-gap-x-10 md:tw-gap-y-6 tw-w-full">
              <div
                v-for="(prop, index) in article.properties"
                :key="index"
                class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-gap-2 md:tw-gap-4"
              >
                <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ prop.label }}</span>
                <span class="tw-text-sm tw-font-normal tw-text-dark-gray">{{ prop.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="os-modal-close"
        @click="$emit('update:model-value', false)"
      >
        <ui-icon-base
          :icon="icons['close']"
          :width="15"
          :height="15"
          :view-box-size="[15, 15]"
          :stroke-width="2"
          stroke="#6C6D70"
        />
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
import { computed, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { VueFinalModal } from 'vue-final-modal'
import { useModalMixin } from '@/mixins/modal.mixin'
import { BASE_URL } from '@/api/config'
import icons from '@/utils/icons'

import DefaultImg from '@/assets/img/default_img.png'

export default {
  name: 'os-article-modal',
  components: {
    VueFinalModal
  },
  props: {
    oem: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const { oem } = toRefs(props)
    const { options } = useModalMixin()
    const article = computed(() => store.state.OsmiModule.articles.find(item => item.oem === oem.value) || {})

    onMounted(async () => {
      if (!store.state.OsmiModule.articles.length) {
        await store.dispatch('OsmiModule/getData')
      }
    })

    return {
      BASE_URL,
      DefaultImg,
      article,
      icons,
      options
    }
  }
}
</script>

<style lang="scss">
@import 'os-article-modal';
</style>
