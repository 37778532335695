<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-gap-6 md:tw-gap-3 5xl:tw-gap-4 tw-w-auto">
    <ui-icon-base
      :icon="icons['fi-sr-minus-small']"
      :width="16"
      :height="16"
      :view-box-size="[16, 16]"
      fill="#EA5432"
      class="tw-cursor-pointer 5xl:tw-w-5 5xl:tw-h-5"
      @click="decreaseHandler"
    />

    <span class="tw-text-base md:tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-dark-gray tw-leading-5">{{ count }}</span>

    <ui-icon-base
      :icon="icons['fi-sr-plus-small']"
      :width="16"
      :height="16"
      :view-box-size="[16, 16]"
      fill="#EA5432"
      class="tw-cursor-pointer 5xl:tw-w-5 5xl:tw-h-5"
      @click="$emit('increase')"
    />
  </div>
</template>

<script>
import icons from '@/utils/icons'

import { toRefs } from 'vue'

export default {
  name: 'os-quantity',
  props: {
    count: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const { count } = toRefs(props)

    function decreaseHandler () {
      if (count.value <= 1) {
        return
      }

      emit('decrease')
    }

    return {
      decreaseHandler,
      icons
    }
  }
}
</script>
