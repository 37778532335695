<template>
  <div class="os-column-start tw-gap-5 3xl:tw-gap-[30px] tw-w-full">
    <div class="tw-flex tw-flex-col lg:tw-flex-row xl:tw-flex-col 3xl:tw-flex-row tw-items-start tw-justify-between tw-w-full tw-gap-5 lg:tw-gap-0 xl:tw-gap-5">
      <div class="os-column-start tw-w-auto tw-gap-4 lg:tw-gap-5 5xl:tw-gap-[25px] xl:tw-w-full xl:tw-max-w-[35%]">
        <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 lg:tw-gap-3 5xl:tw-gap-[15px]">
          <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-black">{{ item.brand }}</span>

          <span
            class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-orange tw-cursor-pointer"
            @click="openArticleModal(item.oem)"
          >
            {{ item.name }}
          </span>
        </div>

        <span class="tw-text-xs 5xl:tw-text-base tw-font-normal tw-text-dark-gray">{{ item.oem }}</span>

        <os-article-status
          :elements="item.status"
          :show-hint="true"
          class="tw-gap-3 5xl:tw-gap-4 tw-mt-1 lg:mt-0"
        />

        <div
          v-if="breakpointGreaterOrEqual3xl"
          class="os-column-start lg:tw-px-4 xl:tw-px-0 tw-gap-3 5xl:tw-gap-4"
        >
          <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray">{{ t('availability') }}</span>

          <ui-dropdown
            :model-value="item.availability[0]"
            :options="item.availability"
            :pt="{ root: { class: 'tw-gap-2 5xl:tw-gap-[10px] tw-py-[10px] sm:tw-py-[8px] 5xl:tw-py-3 tw-px-[14px] 5xl:tw-px-[17px]' } }"
            :merge-pt="true"
          >
            <template #value="{ value }">
              {{ value.name }}: {{ value.available }}
            </template>

            <template #option="{ option }">
              {{ option.name }}: {{ option.available }}
            </template>
          </ui-dropdown>
        </div>
      </div>

      <div class="tw-flex tw-flex-col lg:tw-flex-row xl:tw-flex-col tw-items-stretch tw-w-full lg:tw-w-auto tw-gap-6 lg:tw-gap-4 5xl:tw-gap-5">
        <div
          v-if="!breakpointGreaterOrEqual3xl"
          class="os-column-start lg:tw-px-4 xl:tw-px-0 tw-gap-3 5xl:tw-gap-4"
        >
          <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray">{{ t('availability') }}</span>

          <ui-dropdown
            :model-value="item.availability[0]"
            :options="item.availability"
            :pt="{ root: { class: 'tw-gap-2 5xl:tw-gap-[10px] tw-py-[10px] sm:tw-py-[8px] 5xl:tw-py-3 tw-px-[14px] 5xl:tw-px-[17px]' } }"
            :merge-pt="true"
          >
            <template #value="{ value }">
              {{ value.name }}: {{ value.available }}
            </template>

            <template #option="{ option }">
              {{ option.name }}: {{ option.available }}
            </template>
          </ui-dropdown>
        </div>

        <div class="tw-grid tw-grid-cols-2 lg:tw-flex xl:tw-grid 3xl:tw-flex 3xl:tw-flex-row tw-items-center 3xl:tw-items-start tw-gap-6 5xl:tw-gap-[30px] tw-w-full lg:tw-w-auto">
          <div class="os-column-start tw-gap-3 5xl:tw-gap-4">
            <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px]">
              <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray">{{ t('price') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('price_hint') }}
                </template>
              </tippy>
            </div>

            <div class="os-column-start tw-gap-1">
              <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray tw-line-through tw-whitespace-nowrap">{{ item.price.old }}</span>
              <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-orange tw-whitespace-nowrap">{{ item.price.new }}</span>
            </div>
          </div>

          <div class="os-column-start tw-gap-3 5xl:tw-gap-4">
            <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px]">
              <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray">{{ t('qty') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('qty_hint') }}
                </template>
              </tippy>
            </div>

            <os-quantity
              :count="item.qty"
              class="tw-min-h-[40px]"
              @increase="$emit('increase:qty')"
              @decrease="$emit('decrease:qty')"
            />
          </div>

          <ui-button
            v-if="breakpointGreaterOrEqual3xl"
            class="ui-button_md ui-button_primary tw-min-w-fit 3xl:tw-self-end"
            @click="$emit('add-to-cart', item)"
          >
            <ui-icon-base
              :icon="icons['fi-rr-shopping-cart-add']"
              :width="15"
              :height="16"
              :view-box-size="[15, 16]"
              fill="#FFF"
            />

            {{ t('add_to_cart') }}
          </ui-button>
        </div>

        <ui-button
          v-if="breakpointBetweenLgXl"
          class="ui-button_md ui-button_text tw-min-w-fit"
          @click="$emit('add-to-cart', item)"
        >
          <ui-icon-base
            :icon="icons['fi-rr-shopping-cart-add']"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            fill="#EA5432"
          />

          {{ t('add_to_cart') }}
        </ui-button>
      </div>
    </div>

    <ui-button
      v-if="(breakpointSmallerLg || breakpointGreaterOrEqualXl) && !breakpointGreaterOrEqual3xl"
      class="ui-button_md ui-button_primary"
      @click="$emit('add-to-cart', item)"
    >
      <ui-icon-base
        :icon="icons['fi-rr-shopping-cart-add']"
        :width="15"
        :height="16"
        :view-box-size="[15, 16]"
        fill="#FFF"
      />

      {{ t('add_to_cart') }}
    </ui-button>
  </div>
</template>

<script>
import OsArticleModal from '@/modals/os-article-modal/os-article-modal.vue'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsQuantity from '@/components/os-quantity.vue'
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-card-template-desktop',
  components: {
    OsArticleStatus,
    OsQuantity,
    Tippy
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { t } = useI18n()

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointBetweenLgXl = breakpoints.between('lg', 'xl')
    const breakpointSmallerLg = breakpoints.smaller('lg')
    const breakpointGreaterOrEqualXl = breakpoints.greaterOrEqual('xl')
    const breakpointGreaterOrEqual3xl = breakpoints.greaterOrEqual('3xl')

    function openArticleModal (oem) {
      const { open } = useModal({
        component: OsArticleModal,
        attrs: {
          oem
        }
      })
      open()
    }

    return {
      breakpoints,
      breakpointBetweenLgXl,
      breakpointSmallerLg,
      breakpointGreaterOrEqualXl,
      breakpointGreaterOrEqual3xl,
      icons,
      openArticleModal,
      t
    }
  }
}
</script>
