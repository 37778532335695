export const routeMessages = {
  home: [
    'in_stock', 'analogue_in_stock', 'in_transit', 'assemble_article', 'has_analogue', 'draft', 'cart', 'table', 'cards',
    'search_by_article_placeholder', 'all_filters', 'add_to_cart', 'add_all_to_cart', 'show_more', 'show_more_of', 'download_xlsx',
    'availability', 'price', 'qty', 'properties', 'table_header_oem', 'table_header_product_name', 'table_header_product_status',
    'filters_subtitle', 'filters_title', 'loading', 'price_hint', 'qty_hint', 'toast_title_added_to_cart', 'toast_text_added_all_articles',
    'toast_button_go_to_cart', 'toast_button_cancel', 'ep_filters_apply', 'ep_filters_reset', 'ep_input_placeholder_search', 'PAGE_TITLE_HOME',
    'go_to_product_list', 'no_results_found_title', 'no_results_found_text', 'clear_search', 'filters', 'on_request', 'showing', 'entries',
    'go_to_product_list', 'no_results_found_title', 'no_results_found_text', 'clear_search', 'filters', 'osmi_article_meta_title', 'osmi_article_meta_description',
    'osmi_home_meta_title', 'osmi_home_meta_description'
  ],
  cart: [
    'in_stock', 'analogue_in_stock', 'in_transit', 'assemble_article', 'has_analogue', 'draft', 'cart', 'clear_cart', 'remove', 'toast_title_create_inquiry', 'toast_text_create_inquiry',
    'search_by_article_placeholder', 'download_xlsx', 'go_to_inquiry', 'create_inquiry', 'lead_time', 'offer_price', 'offer_your_price', 'toast_text_delete_all_articles',
    'availability', 'price', 'qty', 'properties', 'table_header_oem', 'table_header_product_name', 'table_header_product_status', 'toast_title_delete_all_articles',
    'inquiry_textarea_desc_headline', 'inquiry_textarea_desc_title', 'inquiry_textarea_desc_text_1', 'inquiry_textarea_desc_text_2', 'inquiry_textarea_desc_text_3', 'inquiry_textarea_desc_text_4',
    'inquiry_textarea_desc_text_5', 'input_label_title', 'input_label_fio', 'input_label_email', 'input_label_phone', 'input_label_company', 'loading', 'price_hint', 'qty_hint',
    'toast_button_go_to_cart', 'toast_button_cancel', 'input_placeholder_description', 'form_required_fio', 'form_required_email', 'form_required_phone',
    'input_placeholder_fio', 'input_placeholder_email', 'input_placeholder_phone', 'input_placeholder_company', 'PAGE_TITLE_CART', 'on_request', 'availability_transit',
    'back_to_product_list', 'cart_empty_title', 'cart_empty_text', 'go_to_product_list', 'headline_in_stock', 'headline_on_request'
  ]
}
