<template>
  <vue-final-modal
    class="tw-flex tw-justify-end tw-w-full tw-pl-6 md:tw-pl-0 tw-h-full tw-max-h-screen tw-z-20 tw-ml-auto"
    content-class="tw-w-full tw-h-full tw-max-h-none md:tw-w-[400px]"
    content-transition="vfm-slide-right"
    overlay-class="md:tw-bg-transparent"
    esc-to-close
    @closed="$emit('update:model-value', false)"
  >
    <div class="tw-flex tw-w-full tw-h-full tw-ml-auto tw-relative tw-z-20">
      <div class="os-column-start tw-pt-6 tw-gap-6 tw-border-l tw-border-middle-gray tw-bg-white tw-shadow-overwrite-xl tw-w-full tw-h-full tw-max-h-full tw-overflow-y-auto">
        <div class="tw-flex tw-flex-col tw-gap-1 tw-w-full tw-px-6">
          <h2 class="tw-text-xl tw-font-semibold tw-text-black">{{ title }}</h2>
          <span class="tw-text-sm tw-font-normal tw-text-gray">{{ subtitle }}</span>
        </div>

        <div class="os-column-start tw-w-full tw-gap-6 tw-px-6 tw-max-h-[79%] tw-overflow-y-auto">
          <os-filter-property
            v-for="item in blocks"
            :key="item.key"
            :label="item.label"
            :type="item.key"
            :elements="item.values"
            :selected-filters="selectedFilters"
            :show-elements="5"
            :show-more="10"
            :is-checkbox="item.checkbox"
            @update:selected-filter="onUpdateFilters"
          />
        </div>

        <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-py-4 tw-px-6 tw-border-t tw-border-middle-gray tw-mt-auto">
          <div class="tw-flex tw-items-center tw-gap-2">
            <ui-button
              class="ui-button_md ui-button_outlined"
              :value="t('ep_filters_reset')"
              @click="onResetFilters"
            />

            <ui-button
              class="ui-button_md ui-button_primary"
              :value="t('ep_filters_apply')"
              @click="onApplyFilters"
            />
          </div>
        </div>
      </div>

      <div
        class="tw-flex tw-items-center tw-justify-center tw-w-9 tw-h-9 tw-absolute tw-top-3 tw-right-5 tw-cursor-pointer"
        @click="$emit('update:model-value', false)"
      >
        <ui-icon-base
          :icon="icons['x-close']"
          :width="20"
          :height="20"
          :view-box-size="[20, 20]"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import OsFilterProperty from './components/os-filter-property.vue'
import { VueFinalModal } from 'vue-final-modal'

import icons from '@/utils/icons'
import { toRefs, ref, onBeforeMount, computed } from 'vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-filters',
  components: {
    OsFilterProperty,
    VueFinalModal
  },
  props: {
    applyFiltersFn: {
      type: Function,
      default: () => {}
    },
    // /**
    //  * @param { { label:string, key:string, elements: { type:string, label:string }[] }[] }
    //  * Массив объектов, где каждый объект это блок с заголовком, поисковой строкой для фильтрации чекбоксов, радио батонов и т.д,
    //  * а так же с чекбоксами или радио батонами, которые лежат в свойстве elements. У каждого объекта в массиве elements
    //  * должно быть 3 свойства:
    //  * - Type, который определяет в какой тип выбранных фильтров попадет значение.
    //  * - Label, который показывается рядом с чекбоксом, а так же попадает в массив выбранных фильтров.
    //  */
    // blocks: {
    //   type: Array,
    //   required: true
    // },
    previousSelectedFilters: {
      type: Object,
      default: () => {}
    },
    subtitle: {
      type: [String, null],
      default: null
    },
    title: {
      type: [String, null],
      default: null
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()

    const breakpoint = useBreakpoints(breakpointsTailwind)
    const breakpointGreaterMd = breakpoint.greater('md')

    const { applyFiltersFn, previousSelectedFilters } = toRefs(props)
    const selectedFilters = ref({})
    // TODO: Из-за костыля получаем фильтры из store, что лишает нас возможности для переиспользования компонента.
    // TODO: Подумать как можно этого избежать сохранив возможность перестройки фильтров по мере выбора.
    const blocks = computed(() => store.state.OsmiModule.filters)
    const getInitiatedFilters = () => {
      return blocks.value.reduce((obj, item) => {
        obj[item.key] = []
        return obj
      }, {})
    }

    onBeforeMount(() => {
      const isThereSelectedFilters = Object.values(previousSelectedFilters.value).some(item => item.length)
      selectedFilters.value = isThereSelectedFilters ? previousSelectedFilters.value : getInitiatedFilters()
    })

    function onApplyFilters () {
      applyFiltersFn.value(selectedFilters.value)
      emit('update:model-value', false)
    }

    function onResetFilters () {
      selectedFilters.value = getInitiatedFilters()
      applyFiltersFn.value(selectedFilters.value)
      emit('update:model-value', false)
    }

    async function onUpdateFilters ({ type, name }) {
      const filterIndex = selectedFilters.value[type].findIndex(item => item === name)

      if (filterIndex !== -1) {
        selectedFilters.value[type].splice(filterIndex, 1)
      } else {
        selectedFilters.value[type].push(name)

        /**
         * TODO: Костыль для перестраивания фильтров при выборе бренда
         */
        if (type === 'brand') {
          store.commit('OsmiModule/setSelectedFilters', selectedFilters.value)
          await store.dispatch('OsmiModule/getData')
        }
      }
    }

    return {
      blocks,
      breakpointGreaterMd,
      icons,
      onApplyFilters,
      onResetFilters,
      onUpdateFilters,
      selectedFilters,
      t
    }
  }
}
</script>
