<template>
  <div
    v-show="isVisible"
    class="os-floating-button tw-bottom-[72px]"
    @click="open"
  >
    <ui-icon-base
      :icon="icons['fi-rr-filter']"
      :width="15"
      :height="16"
      :view-box-size="[15, 16]"
      fill="#FFF"
    />
  </div>
</template>

<script>
import icons from '@/utils/icons'
import { ref, onMounted, onUnmounted } from 'vue'

/**
 * Модуль для отрисовки кнопки скролла к началу страницы
 * @displayName EpScrollToTop
 */
export default {
  name: 'os-floating-filters',
  props: {
    open: {
      type: Function,
      required: true
    }
  },
  setup () {
    const isVisible = ref(false)

    function onResize () {
      isVisible.value = window.scrollY >= (window.innerHeight * 0.8)
    }

    onMounted(() => document.addEventListener('scroll', onResize))
    onUnmounted(() => document.removeEventListener('scroll', onResize))

    return {
      icons,
      isVisible
    }
  }
}
</script>
