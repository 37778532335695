<template>
  <div class="os-column-start tw-w-full">
    <div class="os-column-start tw-w-full tw-py-4 tw-gap-4 tw-px-4">
      <div class="os-column-start tw-w-full tw-gap-3 tw-py-2">
        <span class="tw-text-base tw-font-medium tw-text-dark-gray">{{ item.brand }}</span>

        <span
          class="tw-text-base tw-font-medium tw-text-orange tw-cursor-pointer"
          @click="openArticleModal(item.oem)"
        >
          {{ item.name }}
        </span>
      </div>

      <div class="tw-w-full tw-py-2">
        <os-article-status
          :elements="item.status"
          :show-hint="true"
          class="tw-gap-3"
        />
      </div>

      <div class="tw-grid tw-grid-cols-2 tw-gap-12 tw-py-2">
        <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ item.oem }}</span>
      </div>

      <div class="os-column-start tw-w-full tw-py-2 tw-gap-3">
        <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('availability') }}</span>

        <ui-dropdown
          :model-value="item.availability[0]"
          :options="item.availability"
          :pt="{ root: { class: 'tw-gap-2 tw-py-3 tw-px-[14px]' } }"
          :merge-pt="true"
        >
          <template #value="{ value }">
            {{ value.name }}: {{ value.available }}
          </template>

          <template #option="{ option }">
            {{ option.name }}: {{ option.available }}
          </template>
        </ui-dropdown>
      </div>

      <div class="tw-grid tw-grid-cols-2 tw-w-full tw-py-2 tw-gap-12">
        <div class="os-column-start tw-gap-3">
          <div class="tw-flex tw-items-center tw-gap-2">
            <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('price') }}</span>

            <tippy
              theme="orange"
              content-class="os-tooltip"
              :on-click-outside="instance => instance.hide()"
            >
              <template #default>
                <ui-icon-base
                  :icon="icons['fi-rr-info']"
                  :width="13"
                  :height="12"
                  :view-box-size="[13, 12]"
                />
              </template>

              <template #content>
                {{ t('price_hint') }}
              </template>
            </tippy>
          </div>

          <span class="tw-text-base tw-font-medium tw-text-dark-gray">{{ item.price.new }}</span>
        </div>

        <div class="os-column-start tw-gap-3">
          <div class="tw-flex tw-items-center tw-gap-2">
            <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('qty') }}</span>

            <tippy
              theme="orange"
              content-class="os-tooltip"
              :on-click-outside="instance => instance.hide()"
            >
              <template #default>
                <ui-icon-base
                  :icon="icons['fi-rr-info']"
                  :width="13"
                  :height="12"
                  :view-box-size="[13, 12]"
                />
              </template>

              <template #content>
                {{ t('qty_hint') }}
              </template>
            </tippy>
          </div>

          <os-quantity
            :count="item.qty"
            @increase="$emit('increase:qty')"
            @decrease="$emit('decrease:qty')"
          />
        </div>
      </div>
    </div>

    <accordion class="p-accordion_mobile" :active-index="null">
      <template #collapseicon>
        <div class="tw-px-4 tw-py-3">
          <ui-icon-base
            :icon="icons['chevron-down']"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            :stroke-width="1.6"
            fill="transparent"
            stroke="#EA5432"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="tw-rotate-180"
          />
        </div>
      </template>

      <template #expandicon>
        <div class="tw-px-4 tw-py-3">
          <ui-icon-base
            :icon="icons['chevron-down']"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            :stroke-width="1.6"
            fill="transparent"
            stroke="#454545"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </div>
      </template>

      <template #default>
        <accordion-tab :header="t('properties')">
          <div class="os-column-start tw-my-4 tw-gap-4">
            <div
              v-for="(prop, index) in item.properties"
              :key="index"
              class="os-column-start tw-px-4 tw-py-2 tw-gap-3"
            >
              <span class="tw-text-base tw-font-medium tw-text-gray">{{ prop.label }}</span>
              <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ prop.value }}</span>
            </div>
          </div>
        </accordion-tab>
      </template>
    </accordion>

    <div class="tw-flex tw-px-4 tw-py-6 tw-w-full tw-border-t tw-border-middle-gray">
      <ui-button
        class="ui-button_md ui-button_primary ui-button_w-full"
        @click="$emit('add-to-cart', item)"
      >
        <ui-icon-base
          :icon="icons['fi-rr-shopping-cart-add']"
          :width="15"
          :height="16"
          :view-box-size="[15, 16]"
          fill="#FFF"
        />

        {{ t('add_to_cart') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import OsArticleModal from '@/modals/os-article-modal/os-article-modal.vue'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsQuantity from '@/components/os-quantity.vue'
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-card-template-mobile',
  components: {
    Accordion,
    AccordionTab,
    OsArticleStatus,
    OsQuantity,
    Tippy
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { t } = useI18n()

    function openArticleModal (oem) {
      const { open } = useModal({
        component: OsArticleModal,
        attrs: {
          oem
        }
      })
      open()
    }

    return {
      icons,
      openArticleModal,
      t
    }
  }
}
</script>
