<template>
  <div class="tw-w-full tw-overflow-x-auto">
    <os-table-desktop
      v-if="breakpointGreaterOrEqualMd"
      :items="items"
      :headers="headers"
      @increase:qty="$emit('increase:qty', { index: $event })"
      @decrease:qty="$emit('decrease:qty', { index: $event })"
      @add-to-cart="$emit('add-to-cart', $event)"
    />

    <os-table-mobile
      v-if="breakpointSmallerMd"
      :items="items"
      @increase:qty="$emit('increase:qty', { index: $event })"
      @decrease:qty="$emit('decrease:qty', { index: $event })"
      @add-to-cart="$emit('add-to-cart', $event)"
    />
  </div>
</template>

<script>
import OsTableDesktop from './os-table-desktop.vue'
import OsTableMobile from './os-table-mobile.vue'

import icons from '@/utils/icons'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'os-table',
  components: {
    OsTableDesktop,
    OsTableMobile
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { t } = useI18n()

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterOrEqualMd = breakpoints.greaterOrEqual('md')
    const breakpointSmallerMd = breakpoints.smaller('md')

    const headerClasses = 'tw-flex tw-items-center tw-min-h-[60px] tw-text-xs tw-font-medium tw-text-gray'
    const itemClasses = 'tw-flex tw-items-center tw-min-h-[72px] tw-text-sm tw-font-normal tw-text-dark-gray'
    const headers = computed(() => [
      {
        column: 'oem',
        label: t('table_header_oem'),
        size: 'tw-w-[16%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'name',
        label: t('table_header_product_name'),
        size: 'tw-w-[21%] lg:tw-w-[20%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'status',
        label: t('table_header_product_status'),
        size: 'tw-w-[14%] lg:tw-w-[14%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'availability',
        label: t('availability'),
        size: 'tw-w-[16%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'price',
        label: t('price'),
        size: 'tw-w-[10%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'qty',
        label: t('qty'),
        size: 'tw-w-[10%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      },
      {
        column: 'actions',
        label: '',
        size: 'tw-w-[15%] lg:tw-w-[14%]',
        classesHeader: [headerClasses, 'tw-px-4'],
        classesItem: [itemClasses, 'tw-px-4'],
        onClick: () => {}
      }
    ])

    return {
      breakpoints,
      breakpointGreaterOrEqualMd,
      breakpointSmallerMd,
      headers,
      icons
    }
  }
}
</script>
