import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Libraries and plugins
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import VueTransitions from '@morev/vue-transitions'
import EpartsUi from '@mikael.tadesse/eparts-ui/dist/library'
import '@mikael.tadesse/eparts-ui/dist/library.mjs.css'
import i18n from '@/i18n'

// Styles
import '@/styles/main.scss'

const app = createApp(App)
const vfm = createVfm()

app.use(store)
app.use(router)
app.use(PrimeVue)
app.use(i18n)
app.use(vfm)
app.use(EpartsUi)
app.use(ToastService)
app.use(VueTransitions)

app.mount('#app')
