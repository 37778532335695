<template>
  <div class="os-column-start tw-p-4 tw-border tw-border-light-gray tw-rounded tw-bg-white tw-relative tw-pr-[56px] tw-w-[343px]">
    <div class="tw-flex tw-items-start tw-w-full tw-gap-5">
      <div class="os-toast__icon">
        <ui-icon-base
          :icon="icons['fi-rr-check-1']"
          :width="16"
          :height="16"
          :view-box-size="[16, 16]"
        />
      </div>

      <div class="os-column-start">
        <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ title }}</span>
        <span class="tw-text-sm tw-font-normal tw-text-dark-gray">{{ message }}</span>
      </div>
    </div>

    <div
      class="tw-flex tw-items-center tw-justify-center tw-w-9 tw-h-9 tw-min-w-9 tw-min-h-9 tw-cursor-pointer tw-absolute tw-top-2 tw-right-2"
      @click="$emit('close')"
    >
      <ui-icon-base
        :icon="icons['x-close']"
        :width="10"
        :height="10"
        :view-box-size="[16, 16]"
      />
    </div>
  </div>
</template>

<script>
import ToastMixin from '@/mixins/toast.mixin'
import icons from '@/utils/icons'

export default {
  name: 'os-toast-default',
  mixins: [ToastMixin],
  setup () {
    return {
      icons
    }
  }
}
</script>
