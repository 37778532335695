import { storages } from '@/constants/storages'

import Api from '@/api'
import i18n from '@/i18n'
import { clone } from 'lodash'

export default {
  namespaced: true,
  state: {
    articles: [],
    onRequestArticles: []
  },
  mutations: {
    /**
     * @param state
     * @param { {}[] } articles - Массив артикулов
     */
    addToCart (state, articles) {
      articles.forEach(article => {
        // Ищем в корзине артикул который хотим добавить
        const articleIndex = state.articles.findIndex(item => item.id === article.id)

        // Если нашли, значит надо обновить его количество
        if (articleIndex !== -1) {
          // Записываем в переменную найденый в корзине артикул
          const targetArticle = state.articles[articleIndex]
          // Считаем сумму доступного количества этого артикула
          const countedAvailability = targetArticle.availability.reduce((sum, element) => sum + element.available, 0)

          // Если сумма кол-ва артикулов в корзине и артикула, который мы добавляем больше
          // доступного, то надо делать разбивку
          if ((article.qty + targetArticle.qty) > countedAvailability) {
            // Записываем в количество артикула который уже в корзине максимально доступное кол-во артикулов
            targetArticle.qty = countedAvailability

            // Ищем в массиве с артикулами, цена и сроки доставки у которых по запросу, артикул, что хотим добавить
            const targetOnRequestArticle = state.onRequestArticles.find(item => item.id === article.id)

            // Если артикул был найден, то обновляем его кол-во
            if (targetOnRequestArticle) {
              targetOnRequestArticle.qty += article.qty
            } else {
              // Если не найден, то добавляем в массив этот артикул, предварительно обновив его кол-во
              const onRequestArticle = clone(article)

              onRequestArticle.qty = article.qty
              onRequestArticle.lead_time = i18n.global.t('on_request')
              onRequestArticle.price = i18n.global.t('on_request')

              state.onRequestArticles.push(onRequestArticle)
            }
          } else {
            // Если сумма кол-ва артикулов в корзине и артикула, который мы добавляем меньше
            // доступного, то просто обновляем кол-во у артикула
            targetArticle.qty += article.qty
          }
        } else {
          // Считаем доступное кол-во артикулов
          const countedAvailability = article.availability.reduce((sum, element) => sum + element.available, 0)

          // Если кол-во добавляемого артикула больше суммый доступных, то
          // добавляем в массив articles артикул у которого в qty сумма доступных артикулов,
          // а в массив onRequestArticles артикул у которого в qty разница между кол-вом и суммой доступных
          if (article.qty > countedAvailability) {
            const availableArticle = clone(article)
            const onRequestArticle = clone(article)

            onRequestArticle.qty = article.qty - countedAvailability
            onRequestArticle.lead_time = i18n.global.t('on_request')
            onRequestArticle.price = i18n.global.t('on_request')
            availableArticle.qty = countedAvailability

            state.articles.push(availableArticle)
            state.onRequestArticles.push(onRequestArticle)
          } else {
            // Если кол-во добавляемого артикула меньше доступных,
            // то просто добавляем его в массив articles
            state.articles.push(clone(article))
          }
        }
      })
    },
    clearCart (state) {
      state.articles = []
      state.onRequestArticles = []
    },
    removeArticle (state, { index, source = 'articles' }) {
      state[source].splice(index, 1)
    },
    setCartCache (state) {
      localStorage.setItem(storages['cart-data'], JSON.stringify({
        articles: state.articles,
        onRequestArticles: state.onRequestArticles
      }))
    },
    updateArticleByProperty (state, { id, source = 'articles', property, value }) {
      const targetArticle = state[source].find(item => item.id === id)
      targetArticle[property] = value
    },
    updateRequestArticleQty (state, { article, value }) {
      const copyOfArticle = JSON.parse(JSON.stringify(article))
      const targetArticle = state.onRequestArticles.find(item => item.id === copyOfArticle.id)

      if (!targetArticle) {
        copyOfArticle.lead_time = i18n.global.t('on_request')
        copyOfArticle.price = i18n.global.t('on_request')
        copyOfArticle.qty = value

        state.onRequestArticles.push(copyOfArticle)
      } else {
        targetArticle.qty += value
      }
    }
  },
  actions: {
    async createInquiry ({ commit }, formData) {
      await Api.post('/api/osmi/cart', { data: formData })
      commit('clearCart')
    },
    initCart ({ state }) {
      const { articles = [], onRequestArticles = [] } = JSON.parse(localStorage.getItem(storages['cart-data'])) ?? {}

      state.articles = articles
      state.onRequestArticles = onRequestArticles
    }
  }
}
