<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-w-full md:tw-px-8 tw-py-10 tw-gap-6">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4">
      <div class="tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-rounded-full tw-border-8 tw-border-orange-light tw-bg-orange-light-2 tw-box-content">
        <slot name="icon">
          <ui-icon-base
            :icon="icons['fi-rr-shopping-cart']"
            :width="22"
            :height="22"
            :view-box-size="[21, 21]"
            fill="#EA5432"
            stroke="#EA5432"
          />
        </slot>
      </div>

      <div class="tw-flex tw-flex-col tw-items-center tw-gap-1">
        <h2 class="tw-text-lg tw-font-semibold tw-text-black">{{ title }}</h2>
        <p class="tw-text-base md:tw-text-sm tw-font-normal tw-text-dark-gray tw-text-center">{{ text }}</p>
      </div>
    </div>

    <slot name="actions">
      <ui-button
        class="ui-button_md ui-button_primary"
        href="/"
      >
        <ui-icon-base
          :icon="icons['fi-sr-apps']"
          :width="16"
          :height="16"
          :view-box-size="[16, 16]"
          fill="#FFF"
        />

        <span class="tw-leading-[normal]">{{ t('go_to_product_list') }}</span>
      </ui-button>
    </slot>
  </div>
</template>

<script>
import icons from '@/utils/icons'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-empty-result',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { t } = useI18n()

    return {
      icons,
      t
    }
  }
}
</script>
