import { ref } from 'vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

export const useModalMixin = () => {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const breakpointsSmallerMd = breakpoints.smaller('md')
  const options = ref({
    contentTransition: breakpointsSmallerMd.value ? 'vfm-slide-down' : 'fade',
    swipeToClose: breakpointsSmallerMd.value ? 'down' : 'none'
  })

  return {
    options
  }
}
