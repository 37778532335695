import { nextTick } from 'vue'
import i18n from '@/i18n'

const DEFAULT_TITLE = 'Special sale'

export default async (to, from, next) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  await nextTick()

  const { title = null, description = '' } = to.meta

  document.title = i18n.global.t(title) || DEFAULT_TITLE
  document.querySelector('meta[name="description"]').setAttribute('content', description)
}
