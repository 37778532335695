import Api from '@/api'

export default {
  namespaced: true,
  state: {
    articles: [],
    filters: [],
    page: 1,
    perPage: 10,
    selectedFilters: {},
    totalItems: 0
  },
  mutations: {
    initSelectedFilters (state) {
      state.selectedFilters = state.filters.reduce((obj, item) => {
        obj[item.key] = []
        return obj
      }, {})
    },
    setArticles (state, articles) {
      state.articles = articles.map(item => ({ ...item, qty: 1 }))
    },
    setFilters (state, filters) {
      state.filters = filters
    },
    setSelectedFilters (state, filters) {
      state.selectedFilters = filters
    },
    setPage (state, page) {
      state.page = page
    },
    setTotalItems (state, total) {
      state.totalItems = total
    },
    updateArticleByProperty (state, { property, value, index }) {
      state.articles[index][property] = value
    },
    updateSelectedFiltersByProperty (state, { key, value, isCheckbox }) {
      const targetFilter = state.selectedFilters[key]

      if (isCheckbox) {
        if (targetFilter.includes(value)) {
          const valueIndex = targetFilter.findIndex(item => item === value)
          targetFilter.splice(valueIndex, 1)
        } else {
          targetFilter.push(value)
        }
      } else {
        state.selectedFilters[key] = [value]
      }
    }
  },
  actions: {
    async getData ({ commit, state }, payload) {
      try {
        const isThereAnyFilters = ([].concat(...Object.values(state.selectedFilters))).length
        const data = {
          page: state.page,
          perPage: state.perPage,
          filters: isThereAnyFilters ? state.selectedFilters : {},
          ...payload
        }
        const { articles = [], filters = [], total = 0 } = await Api.post('/api/osmi/articles', {
          data
        })

        commit('setFilters', filters)
        commit('setTotalItems', total)

        return articles
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error(error)
      }
    }
  }
}
