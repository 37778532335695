<template>
  <os-banner />

  <div class="tw-flex tw-flex-col tw-w-full tw-gap-8 4xl:tw-gap-10 tw-px-4 md:tw-px-0">
    <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-8 md:tw-gap-6 4xl:tw-gap-[30px]">
      <os-article-status
        class="tw-flex-wrap tw-gap-4 md:tw-gap-6 4xl:tw-gap-[30px] tw-order-2 md:tw-order-none"
        :show-label="true"
        :elements="['isKit', 'haveOnStorage', 'salesDraw', 'isAnalog', 'haveAnalogOnStorage', 'inTransit']"
      />

      <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-order-1 md:tw-order-none">
        <ui-select-button
          v-if="breakpointGreaterOrEqualMd"
          :active="selectedView"
          :options="viewOptions"
          @update:active="selectedView = $event"
        >
          <template #default="{ item }">
            <div class="tw-flex tw-items-center tw-gap-2">
              <ui-icon-base
                :icon="icons[item.icon]"
                :width="15"
                :height="15"
                :view-box-size="[15, 16]"
              />

              <span>{{ item.name }}</span>
            </div>
          </template>
        </ui-select-button>

        <div class="tw-flex tw-items-center tw-gap-4 tw-w-full md:tw-w-auto">
          <ui-input
            name="search"
            :placeholder="t('search_by_article_placeholder')"
            :model-value="searchQuery"
            :pt="{ root: { class: ['ui-input', 'ui-input_search', 'os-input-search'] } }"
            :merge-pt="true"
            class="tw-relative tw-w-full md:tw-w-[300px]"
            @update:model-value="searchQuery = $event"
          >
            <template #icon-before>
              <div class="tw-flex tw-items-center tw-justify-center tw-h-full">
                <ui-icon-base
                  :icon="icons['fi-rr-search']"
                  :width="16"
                  :height="16"
                  :view-box-size="[16, 16]"
                />
              </div>
            </template>

            <template #icon-after>
              <div
                v-if="searchQuery.length"
                class="tw-flex tw-w-full tw-h-full tw-items-center tw-justify-center tw-relative -tw-right-[6px] tw-gap-[14px] 5xl:tw-gap-[17px] tw-py-[6px]"
              >
                <ui-icon-base
                  :icon="icons['close']"
                  :width="10"
                  :height="10"
                  :view-box-size="[15, 15]"
                  :stroke-width="2"
                  stroke="#454545"
                  class="tw-cursor-pointer"
                  @click="clearSearch"
                />

                <ui-button
                  class="ui-button_md ui-button_primary tw-min-h-[32px] tw-max-h-[32px] tw-px-3"
                  value="Search"
                  @click="searching"
                />
              </div>
            </template>
          </ui-input>

          <div
            v-if="breakpointGreaterOrEqualLg && Object.keys(selectedFilters).length"
            class="tw-flex tw-items-center tw-gap-4"
          >
            <multi-select
              v-for="(item, index) in getFrequentFilters"
              :key="index"
              :pt="multiSelectPt"
              :model-value="null"
              :options="item.values"
              :option-value="null"
              :placeholder="item.label"
              option-label="name"
              @update:model-value="onUpdateFrequentFilter($event, item)"
            >
              <template #dropdownicon>
                <ui-icon-base
                  :icon="icons['chevron-down-xl']"
                  :width="20"
                  :height="20"
                  :view-box-size="[20, 20]"
                  :stroke-width="1.6"
                  fill="#FFF"
                  stroke="#454545"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </template>

              <template #value="{ placeholder }">
                <span v-if="!selectedFilters[item.key].length">{{ placeholder }}</span>

                <span
                  v-else-if="selectedFilters[item.key].length && !item.checkbox"
                  class="tw-font-medium tw-text-orange"
                >
                  {{ selectedFilters[item.key][0] }}
                </span>

                <span
                  v-else
                  class="tw-font-medium tw-text-orange"
                >
                  {{ item.label }} ({{ selectedFilters[item.key].length }})
                </span>
              </template>

              <template #option="{ option }">
                <ui-checkbox
                  v-if="item.checkbox"
                  :value="selectedFilters[item.key].includes(option.name)"
                  :classes="['ui-checkbox_base']"
                />

                <ui-radio-button
                  v-else
                  :selected="selectedFilters[item.key][0] || ''"
                  :value="option.name"
                  :pt="{ input: { class: 'tw-rounded-full' } }"
                  :merge-pt="true"
                  @click="() => {}"
                />

                {{ option.name }}
              </template>
            </multi-select>
          </div>

          <ui-button
            :class="[
              'ui-button_md ui-button_outlined tw-w-11 md:tw-w-auto tw-h-11 md:tw-h-auto',
              { 'ui-button_disabled' : itemsLoading }
            ]"
            @click="openFilters"
          >
            <template #default>
              <div class="tw-relative">
                <ui-icon-base
                  :icon="icons['fi-rr-filter']"
                  :width="15"
                  :height="16"
                  :view-box-size="[15, 16]"
                />

                <div
                  v-if="!isEmptySelectedFilters"
                  class="tw-w-2 tw-h-2 tw-rounded-full tw-bg-orange tw-absolute -tw-top-0.5 -tw-right-px"
                />
              </div>

              <span class="tw-font-normal tw-hidden md:tw-block">{{ breakpointGreaterOrEqualLg ? t('all_filters') : t('filters') }}</span>
            </template>
          </ui-button>
        </div>
      </div>

      <ui-select-button
        v-if="breakpointSmallerMd"
        :active="selectedView"
        :options="viewOptions"
        class="tw-order-3 tw-w-full"
        classes="tw-grid md:tw-flex tw-grid-cols-2 tw-w-full md:tw-w-auto"
        @update:active="selectedView = $event"
      >
        <template #default="{ item }">
          <div class="tw-flex tw-items-center tw-gap-2">
            <ui-icon-base
              :icon="icons[item.icon]"
              :width="15"
              :height="15"
              :view-box-size="[15, 16]"
            />

            <span>{{ item.name }}</span>
          </div>
        </template>
      </ui-select-button>

      <div class="os-column-start tw-w-full tw-gap-6 tw-order-4 md:tw-order-none">
        <transition-slide
          v-show="!itemsLoading"
          :group="true"
          :offset="['100%', '0']"
          class="tw-w-full tw-overflow-x-hidden"
        >
          <os-table
            v-if="selectedView === 0"
            :items="items"
            @increase:qty="updateArticleByProperty($event.index, 'qty', items[$event.index].qty + 1)"
            @decrease:qty="updateArticleByProperty($event.index, 'qty', items[$event.index].qty - 1)"
            @add-to-cart="addToCartHandler([$event])"
          />

          <div
            v-else
            class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-w-full tw-gap-6"
          >
            <os-card
              v-for="(item, index) in items"
              :key="index"
              :item="item"
              @increase:qty="updateArticleByProperty(index, 'qty', item.qty + 1)"
              @decrease:qty="updateArticleByProperty(index, 'qty', item.qty - 1)"
              @add-to-cart="addToCartHandler([$event])"
            />
          </div>
        </transition-slide>

        <div
          v-show="itemsLoadingMore || itemsLoading"
          class="tw-relative tw-w-full tw-h-[120px]"
        >
          <ui-loader
            :loading="itemsLoadingMore || itemsLoading"
            :text="t('loading')"
          />
        </div>

        <os-empty-result
          v-if="!(itemsLoadingMore || itemsLoading) && !items.length"
          :title="t('no_results_found_title')"
          :text="t('no_results_found_text')"
        >
          <template #icon>
            <ui-icon-base
              :icon="icons['fi-rr-search']"
              :width="20"
              :height="20"
              :view-box-size="[16, 16]"
              fill="#EA5432"
            />
          </template>

          <template #actions>
            <ui-button
              class="ui-button_md ui-button_primary"
              @click="clearSearch"
            >
              <ui-icon-base
                :icon="icons['fi-rr-cross-small']"
                :width="15"
                :height="16"
                :view-box-size="[15, 16]"
              />

              <span>{{ t('clear_search') }}</span>
            </ui-button>
          </template>
        </os-empty-result>

        <div class="os-column-start tw-w-full tw-gap-8 md:tw-gap-4">
          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-items-center tw-w-full tw-gap-4 tw-pt-2 md:tw-pt-3 md:tw-pb-4">
            <ui-button
              class="ui-button_md ui-button_outlined tw-w-full md:tw-w-fit tw-order-2 md:tw-order-none"
              :value="t('download_xlsx')"
              @click="generateXlsxHandler"
            />

            <ui-button
              :class="[
                'ui-button_md ui-button_outlined ui-button_orange tw-w-full md:tw-w-fit md:tw-mx-auto',
                { 'ui-button_disabled' : items.length === itemsTotal || itemsLoading || itemsLoadingMore }
              ]"
              @click="loadMore"
            >
              <ui-icon-base
                :icon="icons['fi-rr-apps-add']"
                :width="15"
                :height="16"
                :view-box-size="[15, 16]"
                fill="#EA5432"
              />

              {{ t('show_more') }}
            </ui-button>

            <ui-button
              class="ui-button_md ui-button_outlined tw-w-full md:tw-w-fit md:tw-ml-auto tw-mt-4 md:tw-mt-0"
              @click="addToCartHandler(items)"
            >
              <template #default>
                <ui-icon-base
                  :icon="icons['fi-rr-shopping-cart-add']"
                  :width="15"
                  :height="16"
                  :view-box-size="[15, 16]"
                />

                <span>{{ t('add_all_to_cart') }}</span>
              </template>
            </ui-button>
          </div>

          <span class="tw-text-base md:tw-text-sm tw-font-normal tw-text-gray">{{ t('showing') }} {{ loadedItemsInformation }} {{ t('entries') }}</span>
        </div>
      </div>
    </div>

    <os-floating-filters :open="openFilters" />
    <os-toast />
  </div>
</template>

<script>
// components
import MultiSelect from 'primevue/multiselect'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsBanner from './components/os-banner.vue'
import OsCard from '@/views/Home/components/os-card/os-card.vue'
import OsEmptyResult from '@/components/os-empty-result.vue'
import OsFilters from '@/modules/os-filters/os-filters.vue'
import OsFloatingFilters from './components/os-floating-filters.vue'
import OsTable from '@/views/Home/components/os-table/os-table.vue'
import OsToast from '@/modules/os-toast/os-toast.vue'
import { TransitionSlide } from '@morev/vue-transitions/vue3'

// utils
import GenerateXlsx from '@/classes/Generate-xlsx'
import icons from '@/utils/icons'
import { useStore } from 'vuex'
import { ref, computed, onBeforeMount } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useToast } from 'primevue/usetoast'
import { useModal } from 'vue-final-modal'
import { TOAST_TYPES } from '@/constants/toast'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'home',
  components: {
    MultiSelect,
    OsArticleStatus,
    OsBanner,
    OsCard,
    OsEmptyResult,
    OsFloatingFilters,
    OsTable,
    OsToast,
    TransitionSlide
  },
  setup () {
    const store = useStore()
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2lg': '1180px',
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterOrEqualMd = breakpoints.greaterOrEqual('md')
    const breakpointGreaterOrEqualLg = breakpoints.greaterOrEqual('lg')
    const breakpointGreaterOrEqual2lg = breakpoints.greaterOrEqual('2lg')
    const breakpointSmallerMd = breakpoints.smaller('md')

    const selectedView = ref(1)
    const viewOptions = computed(() => [
      {
        id: 0,
        name: t('table'),
        icon: 'sr-list'
      },
      {
        id: 1,
        name: t('cards'),
        icon: selectedView.value ? 'fi-sr-apps' : 'rr-apps'
      }
    ])

    // Данные для вывода артикулов, данных о загруженных артикулах, методы получения артикулов и т.д.
    const items = computed(() => store.state.OsmiModule.articles)
    const itemsLoading = ref(false)
    const itemsLoadingMore = ref(false)
    const itemsTotal = computed(() => store.state.OsmiModule.totalItems)
    const loadedItemsInformation = computed(() => {
      const currentItemsCount = store.state.OsmiModule.page * store.state.OsmiModule.perPage
      return `${currentItemsCount > itemsTotal.value ? itemsTotal.value : currentItemsCount} ${t('show_more_of')} ${itemsTotal.value}`
    })

    async function loadMore () {
      store.commit('OsmiModule/setPage', ++store.state.OsmiModule.page)

      itemsLoadingMore.value = true

      const articles = await store.dispatch('OsmiModule/getData', {
        query: searchQuery.value
      })

      store.commit('OsmiModule/setArticles', [...items.value, ...articles])
      itemsLoadingMore.value = false
    }

    // Поисковая логика
    const searchQuery = ref('')

    async function searching () {
      store.commit('OsmiModule/setPage', 1)
      itemsLoading.value = true

      const articles = await store.dispatch('OsmiModule/getData', {
        query: searchQuery.value
      })

      store.commit('OsmiModule/setArticles', articles)
      itemsLoading.value = false
    }

    async function clearSearch () {
      searchQuery.value = ''

      if (route.params.articleCode) {
        await router.push('/')
      }

      await searching()
    }

    // Логика фильтрации, открытия фильтров и т.д.
    const multiSelectPt = {
      root: {
        class: 'tw-flex tw-items-center tw-h-11 md:tw-h-10 5xl:tw-h-11 tw-px-[14px] tw-gap-2 tw-border tw-border-middle-gray tw-rounded-[5px]'
      },
      label: {
        class: 'tw-text-base md:tw-text-sm tw-font-normal tw-text-dark-gray'
      },
      panel: {
        class: 'tw-mt-1 tw-py-1 tw-rounded-[4px] tw-border tw-border-middle-gray tw-bg-white'
      },
      item: {
        class: 'tw-flex tw-items-center tw-w-full tw-px-[14px] tw-py-3 tw-gap-2'
      },
      option: {
        class: 'tw-text-base md:tw-text-sm tw-font-normal tw-text-dark-gray tw-leading-[normal]'
      },
      header: {
        class: 'tw-hidden'
      },
      checkboxcontainer: {
        class: 'tw-absolute tw-top-[9999px] tw-right-[9999px] tw-hidden'
      }
    }
    const filters = computed(() => store.state.OsmiModule.filters)
    const frequentFilters = computed(() => breakpointGreaterOrEqual2lg.value ? ['brand', 'subtype', 'model'] : ['subtype', 'model'])
    const getFrequentFilters = computed(() => store.state.OsmiModule.filters.filter(item => frequentFilters.value.includes(item.key)))
    const selectedFilters = computed(() => store.state.OsmiModule.selectedFilters)
    const isEmptySelectedFilters = computed(() => {
      const selectedFilters = store.state.OsmiModule.selectedFilters

      if (!Object.keys(selectedFilters).length) {
        return true
      }

      return Object.values(selectedFilters).every(item => !item.length)
    })

    function openFilters () {
      const { open } = useModal({
        component: OsFilters,
        attrs: {
          applyFiltersFn: async (filters) => {
            await onUpdateSelectedFilters(filters)
          },
          blocks: filters.value,
          previousSelectedFilters: selectedFilters.value,
          subtitle: t('filters_subtitle'),
          title: t('filters_title')
        }
      })

      open()
    }

    async function onUpdateSelectedFilters (filters) {
      selectedFilters.value = filters
      store.commit('OsmiModule/setSelectedFilters', filters)
      store.commit('OsmiModule/setPage', 1)
      itemsLoading.value = true

      const articles = await store.dispatch('OsmiModule/getData', {
        query: searchQuery.value
      })

      store.commit('OsmiModule/setArticles', articles)
      itemsLoading.value = false
    }

    async function onUpdateFrequentFilter (value, filter) {
      store.commit('OsmiModule/updateSelectedFiltersByProperty', { key: filter.key, isCheckbox: filter.checkbox, value: value[0].name })

      itemsLoading.value = true

      const articles = await store.dispatch('OsmiModule/getData', {
        query: searchQuery.value
      })
      store.commit('OsmiModule/setArticles', articles)

      itemsLoading.value = false
    }

    onBeforeMount(async () => {
      itemsLoading.value = true

      const articles = await store.dispatch('OsmiModule/getData', {
        query: ''
      }) || []

      store.commit('OsmiModule/setArticles', articles)
      store.commit('OsmiModule/initSelectedFilters')
      itemsLoading.value = false
      await store.dispatch('CartModule/initCart')

      if (route.params.articleCode) {
        await showArticlePage(route.params.articleCode)
      }
    })

    async function showArticlePage (articleCode) {
      searchQuery.value = articleCode
      await searching()

      if (store.state.OsmiModule.articles.length !== 1) {
        await clearSearch()
        return
      }

      const article = store.state.OsmiModule.articles[0]

      document.title = t('osmi_article_meta_title')
        .replace('#NAME#', article.name)
        .replace('#OEM#', article.oem)
        .replace('#PRICE#', article.price.new)
        .replace('#BRAND#', article.brand)

      document.querySelector('meta[name="description"]').setAttribute('content', t('osmi_article_meta_description')
        .replace('#NAME#', article.name)
        .replace('#OEM#', article.oem)
        .replace('#PRICE#', article.price.new)
        .replace('#BRAND#', article.brand)
      )
    }

    // Мето для добавления артикула
    async function addToCartHandler (articles) {
      store.commit('CartModule/addToCart', articles)
      toast.add({
        detail: {
          title: t('toast_title_added_to_cart'),
          text: articles.length > 1 ? t('toast_text_added_all_articles') : articles[0].name,
          type: TOAST_TYPES.cart
        }
      })
    }

    async function generateXlsxHandler () {
      const headers = [
        { key: 'oem', header: 'OEM Article', width: 30 },
        { key: 'name', header: 'Product name', width: 30 },
        { key: 'qty', header: 'Quantity', width: 30 }
      ]
      const xlsx = new GenerateXlsx(headers, items.value)

      xlsx.createSimpleSheet()
      await xlsx.generate()
    }

    return {
      addToCartHandler,
      breakpoints,
      breakpointGreaterOrEqualMd,
      breakpointGreaterOrEqualLg,
      breakpointSmallerMd,
      clearSearch,
      generateXlsxHandler,
      getFrequentFilters,
      icons,
      items,
      itemsLoading,
      itemsLoadingMore,
      itemsTotal,
      isEmptySelectedFilters,
      loadMore,
      loadedItemsInformation,
      multiSelectPt,
      onUpdateFrequentFilter,
      openFilters,
      searching,
      searchQuery,
      selectedFilters,
      selectedView,
      t,
      viewOptions,
      updateArticleByProperty: (index, property, value) => store.commit('OsmiModule/updateArticleByProperty', { index, property, value }),
      TOAST_TYPES
    }
  }
}
</script>
