<template>
  <div class="tw-w-full">
    <os-table-desktop
      v-if="breakpointGreaterOrEqualMd"
      :headers="headers"
      :items="items"
      :is-transit="isTransit"
      @remove:article="$emit('remove-article', $event)"
      @update:article-by-property="$emit('update:article-by-property', $event)"
      @update:qty="$emit('update:qty', $event)"
    />

    <os-table-mobile
      v-if="breakpointSmallerMd"
      :items="items"
      :offer-price="offerPrice"
      :is-transit="isTransit"
      @remove:article="$emit('remove-article', $event)"
      @update:article-by-property="$emit('update:article-by-property', $event)"
      @update:qty="$emit('update:qty', $event)"
    />
  </div>
</template>

<script>
import OsTableDesktop from '@/views/Cart/components/os-table/os-table-desktop.vue'
import OsTableMobile from '@/views/Cart/components/os-table/os-table-mobile.vue'

import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export default {
  name: 'os-table',
  components: {
    OsTableDesktop,
    OsTableMobile
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    isTransit: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    offerPrice: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-article', 'update:article-by-property', 'update:qty'],
  setup () {
    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterOrEqualMd = breakpoints.greaterOrEqual('md')
    const breakpointSmallerMd = breakpoints.smaller('md')

    return {
      breakpoints,
      breakpointGreaterOrEqualMd,
      breakpointSmallerMd
    }
  }
}
</script>
