<template>
  <div class="os-column-start tw-w-full tw-gap-6">
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="os-column-start tw-w-full tw-border tw-border-middle-gray tw-rounded-[5px]"
    >
      <div class="os-column-start tw-w-full tw-p-4 tw-gap-4">
        <div class="os-column-start tw-w-full tw-py-2 tw-gap-3">
          <span
            class="tw-text-base tw-font-medium tw-text-gray tw-cursor-pointer"
            @click="openArticleModal(item.oem)"
          >
            {{ item.name }}
          </span>

          <span class="tw-text-base tw-font-medium tw-text-orange">{{ item.brand }}</span>
        </div>

        <div class="os-column-start tw-w-full tw-py-2 tw-gap-3">
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('table_header_oem') }}</span>
          <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ item.oem }}</span>
        </div>

        <div class="os-column-start tw-w-full tw-py-2 tw-gap-3">
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('table_header_product_status') }}</span>

          <os-article-status
            :elements="item.status"
            :show-hint="true"
            class="tw-gap-3"
          />
        </div>

        <div class="os-column-start tw-w-full tw-py-2 tw-gap-3">
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('availability') }}</span>

          <ui-dropdown
            :model-value="item.availability[0]"
            :options="item.availability"
            :pt="{ root: { class: 'tw-gap-2 tw-py-[10px] tw-px-[14px] sm:tw-py-[8px]' } }"
            :merge-pt="true"
          >
            <template #value="{ value }">
              {{ value.name }}: {{ value.available }} / {{ value.transit }}
            </template>

            <template #option="{ option }">
              {{ option.name }}: {{ option.available }} / {{ option.transit }}
            </template>
          </ui-dropdown>
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-py-2 tw-w-full tw-gap-4">
          <div class="os-column-start tw-w-full tw-gap-3">
            <div class="tw-flex tw-items-center tw-gap-2">
              <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('price') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('price_hint') }}
                </template>
              </tippy>
            </div>

            <span class="tw-text-base tw-font-medium tw-text-dark-gray">{{ item.price.new }}</span>
          </div>

          <div class="os-column-start tw-w-full tw-gap-3">
            <div class="tw-flex tw-items-center tw-gap-2">
              <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('qty') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('qty_hint') }}
                </template>
              </tippy>
            </div>

            <os-quantity
              :count="item.qty"
              @increase="$emit('increase:qty', index)"
              @decrease="$emit('decrease:qty', index)"
            />
          </div>
        </div>
      </div>

      <div class="tw-flex tw-py-6 tw-px-4 tw-w-full">
        <ui-button
          class="ui-button_md ui-button_primary tw-w-full xs:tw-w-auto"
          @click="$emit('add-to-cart', item)"
        >
          <ui-icon-base
            :icon="icons['fi-rr-shopping-cart-add']"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            fill="#FFF"
            class="tw-cursor-pointer"
          />

          {{ t('add_to_cart') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import OsArticleModal from '@/modals/os-article-modal/os-article-modal.vue'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsQuantity from '@/components/os-quantity.vue'
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-table-mobile',
  components: {
    OsArticleStatus,
    OsQuantity,
    Tippy
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { t } = useI18n()

    function openArticleModal (oem) {
      const { open } = useModal({
        component: OsArticleModal,
        attrs: {
          oem
        }
      })
      open()
    }

    return {
      icons,
      openArticleModal,
      t
    }
  }
}
</script>
