import Api from '@/api'
import i18n from '@/i18n'

import LangRu from '@/assets/img/langs/lang-ru.png'
import LangEn from '@/assets/img/langs/lang-en.png'

export default {
  namespaced: true,
  state: {
    languages: [
      {
        name: 'ru',
        code: 'ru',
        img: LangRu
      },
      {
        name: 'en',
        code: 'en',
        img: LangEn
      }
    ],
    selectedLng: null
  },
  mutations: {
    /**
     * @param { state } state
     * @param { { naem:string, code:string, img:string } } lang
     */
    setLang (state, lang) {
      state.selectedLng = lang
      i18n.global.locale.value = lang.code
    }
  },
  actions: {
    async getMessages ({ commit, state }, { keys, lang = null }) {
      try {
        const locale = lang || state.selectedLng.code
        const { translates = {} } = await Api.post('/api/osmi/translates', {
          data: {
            local: locale,
            translates: keys
          }
        })
        i18n.global.messages.value[locale] = translates
      } catch (error) {
        console.error(error)
      }
    }
  }
}
