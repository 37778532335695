import * as Excel from 'exceljs'
import * as fileSaver from 'file-saver'

class Xlsx {
  /**
   * @param { { key: string, header: string }[] } [headers=null]
   * @param { Object[] } [items=null] - Массик объектов. В каждом объекте должны быть свойства перечисленные в headers.
   */
  constructor (headers = null, items = null) {
    this.headers = headers
    this.items = items
    this.payload = null
  }

  /**
   * @param { string } [name='report']
   * @return {string}
   */
  generateFilename (name = 'report') {
    return `${name}_${new Date().toLocaleString().slice(0, 10)}.xlsx`
  }

  /**
   * @param { string } [key='key'] - Ключ свойство в котором будет хранится значение
   */
  createSimpleSheet (key = 'key') {
    if (!(this.headers && this.items)) {
      /* eslint-disable-next-line no-console */
      console.error('При инициализации класса не был передан массив headers', { headers: this.headers, items: this.items })
    }

    this.payload = {
      columns: this.headers,
      cells: this.items.map(item => this.headers.map(header => item[header[key]]))
    }
  }

  async generate () {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('List')
    const { columns, cells } = this.payload

    worksheet.columns = columns
    worksheet.addRows(cells)

    /**
     * Прописываем стили для колонок в первой строке (названия колонок)
     * @type {{size: number, color: {argb: string}, bold: boolean}}
     */
    worksheet.getRow(1).font = {
      bold: true,
      size: 14,
      color: {
        argb: 'FFFFFF'
      }
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'ea5239'
      }
    }

    const file = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const filename = this.generateFilename()
    const blob = new Blob([file], { type: fileType })

    await fileSaver.saveAs(blob, filename)
  }
}

export default Xlsx
