<template>
  <header class="os-header">
    <a href="https://ru.miningelement.com/">
      <ui-icon-base
        :icon="icons.logo"
        :width="119"
        :height="34"
        :view-box-size="[119, 34]"
        class=""
      />
    </a>

    <div class="tw-flex tw-items-center tw-gap-1 md:tw-gap-6 5xl:tw-gap-7">
      <ui-button
        class="ui-button_md"
        href="/cart"
      >
        <ui-icon-base
          :icon="icons['fi-rr-shopping-cart']"
          :width="18"
          :height="18"
          :view-box-size="[21, 21]"
        />

        <span class="tw-font-medium tw-leading-[15px] tw-text-dark-gray">{{ t('cart') }}</span>

        <div
          v-if="cartArticlesLength"
          class="tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-rounded-full tw-bg-orange-light-4"
        >
          <span class="tw-text-xs tw-font-medium tw-text-orange tw-leading-[150%]">{{ cartArticlesLength }}</span>
        </div>
      </ui-button>

      <ui-dropdown
        v-if="false"
        :options="currencies"
        :model-value="selectedCurrency"
        :merge-pt="true"
        :pt="ptDropdown"
        optional-label="name"
        class="tw-w-auto tw-border-none tw-shadow-none tw-px-0"
        @update:model-value="setCurrency($event)"
      >
        <template #value="{ value }">
          <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px">
            <ui-icon-base
              :icon="icons[value.icon.name]"
              :width="value.icon.width"
              :height="value.icon.height"
              :view-box-size="value.icon.viewBoxSize"
              :fill="value.icon.fill"
              class="5xl:tw-w-[25px] 5xl:tw-h-[25px]"
            />

            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-dark-gray tw-uppercase">{{ value.name }}</span>
          </div>
        </template>

        <template #option="{ option }">
          <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px]">
            <ui-icon-base
              :icon="icons[option.icon.name]"
              :width="option.icon.width"
              :height="option.icon.height"
              :view-box-size="option.icon.viewBoxSize"
              :fill="option.icon.fill"
              class="5xl:tw-w-[25px] 5xl:tw-h-[25px]"
            />

            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-uppercase">{{ option.name }}</span>
          </div>
        </template>
      </ui-dropdown>

      <ui-dropdown
        :options="lngs"
        :model-value="selectedLng"
        :merge-pt="true"
        :pt="ptDropdown"
        :option-value="null"
        option-label="name"
        class="tw-w-auto tw-border-none tw-shadow-none tw-px-0"
        @update:model-value="setLng($event)"
      >
        <template #value="{ value }">
          <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px">
            <img
              :src="value.img"
              alt="lang icon"
              class="tw-w-5 tw-h-5 5xl:tw-w-[25px] 5xl:tw-h-[25px]"
            >

            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-dark-gray tw-uppercase">{{ value.name }}</span>
          </div>
        </template>

        <template #option="{ option }">
          <div class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px]">
            <img
              :src="option.img"
              alt="lang icon"
              class="tw-w-5 tw-h-5 5xl:tw-w-[25px] 5xl:tw-h-[25px]"
            >

            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-uppercase">{{ option.name }}</span>
          </div>
        </template>
      </ui-dropdown>
    </div>
  </header>
</template>

<script>
import icons from '@/utils/icons'

import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default {
  name: 'os-header',
  setup () {
    const store = useStore()
    const route = useRoute()

    const ptDropdown = {
      list: {
        class: ['os-dropdown__list']
      },
      item: {
        class: ['os-dropdown__item']
      }
    }

    const currencies = computed(() => store.state.currencies)
    const selectedCurrency = computed(() => store.state.selectedCurrency || currencies.value[0])
    const lngs = computed(() => store.state.LocalizationModule.languages)
    const selectedLng = computed(() => store.state.LocalizationModule.selectedLng || lngs.value[0])

    const { t } = useI18n()

    onMounted(() => {
      store.commit('LocalizationModule/setLang', lngs.value[0])
    })

    async function setLng (lng) {
      await store.dispatch('LocalizationModule/getMessages', { keys: route.meta.message_keys, lang: lng.code })
      store.commit('LocalizationModule/setLang', lng)

      document.title = t(route.meta.title)

      const articles = await store.dispatch('OsmiModule/getData') || []
      store.commit('OsmiModule/setArticles', articles)
    }

    return {
      cartArticlesLength: computed(() => store.state.CartModule.articles.length),
      currencies,
      icons,
      lngs,
      ptDropdown,
      selectedCurrency,
      selectedLng,
      setCurrency: (currency) => store.commit('setCurrency', currency),
      setLng,
      t
    }
  }
}
</script>

<style lang="scss">
@import './os-header';
</style>
