<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-items-start tw-w-full tw-rounded-[5px] tw-border tw-border-middle-gray md:tw-py-5 md:tw-px-4 lg:tw-p-4 5xl:tw-p-5 md:tw-gap-8 5xl:tw-gap-10">
    <div class="tw-relative md:tw-max-w-[140px] lg:tw-max-w-[180px] 5xl:tw-max-w-[200px] tw-w-full">
      <Image
        :src="`${BASE_URL}/${item.picture}` || DefaultImg"
        :pt="ptImage"
        alt="article preview"
        preview
        class="tw-object-cover tw-w-full tw-h-full tw-max-w-[150px] md:tw-max-w-full tw-mx-auto md:tw-mx-0"
      >
        <template #indicatoricon>
          <ui-icon-base
            :icon="icons.preview"
            :width="14"
            :height="14"
            :view-box-size="[14, 14]"
            fill="#FFF"
          />
        </template>
      </Image>

      <span
        v-if="item.discount"
        class="tw-px-2 tw-py-1 tw-rounded tw-bg-orange-light tw-text-sm tw-font-black tw-text-orange tw-absolute tw-top-2 md:-tw-top-2 tw-left-2 md:-tw-left-2"
      >
        -{{ item.discount }}%
      </span>
    </div>

    <os-card-template-desktop
      v-if="breakpointGreaterOrEqualMd"
      :item="item"
      @add-to-cart="$emit('add-to-cart', $event)"
      @increase:qty="$emit('increase:qty')"
      @decrease:qty="$emit('decrease:qty')"
    />

    <os-card-template-mobile
      v-if="!breakpointGreaterOrEqualMd"
      :item="item"
      @add-to-cart="$emit('add-to-cart', $event)"
      @increase:qty="$emit('increase:qty')"
      @decrease:qty="$emit('decrease:qty')"
    />
  </div>
</template>

<script>
import Image from 'primevue/image'
import OsCardTemplateDesktop from './os-card-template-desktop.vue'
import OsCardTemplateMobile from './os-card-template-mobile.vue'

import DefaultImg from '@/assets/img/default_img.png'

import icons from '@/utils/icons'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { BASE_URL } from '@/api/config'

export default {
  name: 'os-card',
  components: {
    Image,
    OsCardTemplateDesktop,
    OsCardTemplateMobile
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px',
      '5xl': '2000px'
    })
    const breakpointGreaterOrEqualMd = breakpoints.greaterOrEqual('md')
    const breakpointGreaterOrEqual5xl = breakpoints.greaterOrEqual('5xl')

    const actionIcon = ['tw-text-white']
    const actionButton = ['tw-w-9 tw-h-9 tw-rounded-lg hover:tw-bg-zinc-800']
    const ptImage = {
      root: {
        class: ['tw-flex tw-justify-center tw-items-center tw-py-4']
      },
      button: {
        class: ['tw-bg-zinc-800/50']
      },
      icon: {
        class: ['tw-fill-white']
      },
      mask: {
        class: ['tw-bg-zinc-800/[.85]']
      },
      toolbar: {
        class: ['tw-flex tw-gap-2 tw-pt-2 tw-pr-2']
      },
      rotaterightbutton: {
        class: actionButton
      },
      rotaterighticon: {
        class: actionIcon
      },
      rotateleftbutton: {
        class: actionButton
      },
      rotatelefticon: {
        class: actionIcon
      },
      zoomoutbutton: {
        class: actionButton
      },
      zoomouticon: {
        class: actionIcon
      },
      zoominbutton: {
        class: actionButton
      },
      zoominicon: {
        class: actionIcon
      },
      closebutton: {
        class: actionButton
      },
      closeicon: {
        class: actionIcon
      }
    }

    return {
      BASE_URL,
      breakpoints,
      breakpointGreaterOrEqualMd,
      breakpointGreaterOrEqual5xl,
      icons,
      DefaultImg,
      ptImage
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/mixins/media';

.p-accordion {
  &_mobile {
    @apply tw-w-full;

    .p-accordion-header {
      @apply tw-py-4 tw-border tw-border-y-middle-gray tw-border-x-transparent tw-w-full;
    }

    .p-accordion-tab {
      &[data-p-active="true"] {
        .p-accordion-header {
          @apply tw-border tw-border-orange;
          background: rgba(234, 84, 50, 0.08);
        }

        .p-accordion-header-text {
          @apply tw-text-orange;
        }
      }
    }
  }

  &-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-header {
    &,&-action {
      @apply tw-flex tw-items-center tw-gap-2;
    }

    &-text {
      @apply tw-text-base tw-font-medium tw-px-4;

      @include md {
        @apply tw-text-xs tw-px-0 tw-text-orange;
      }
    }
  }
}
</style>
