<template>
  <form-validate
    class="os-column-start tw-gap-6 tw-w-full"
    :validation-schema="schema"
    @submit="onCreateInquiry"
  >
    <h2 class="tw-text-lg md:tw-text-xl tw-font-semibold tw-text-black">{{ t('create_inquiry') }}</h2>

    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-items-start tw-w-full tw-gap-5 md:tw-gap-6">
      <div class="os-column-start tw-gap-5 tw-w-full">
        <ep-validate-input
          v-model="form.title"
          name="title"
          :placeholder="t('input_placeholder_description')"
          :label="t('input_label_title')"
          class="tw-w-full"
        />

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5 md:tw-gap-4 tw-w-full">
          <ep-validate-input
            v-model="form.fio"
            name="fio"
            :placeholder="t('input_placeholder_fio')"
            :label="t('input_label_fio')"
          />

          <ep-validate-input
            v-model="form.email"
            name="email"
            :placeholder="t('input_placeholder_email')"
            :label="t('input_label_email')"
          />
        </div>

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5 md:tw-gap-4 tw-w-full">
          <ep-validate-input
            v-model="form.phone"
            name="phone"
            :placeholder="t('input_placeholder_phone')"
            :label="t('input_label_phone')"
          />

          <ep-validate-input
            v-model="form.company"
            name="company"
            :placeholder="t('input_placeholder_company')"
            :label="t('input_label_company')"
          />
        </div>
      </div>

      <div class="os-column-start tw-gap-[6px] tw-h-full">
        <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ t('inquiry_textarea_desc_headline') }}</span>

        <ui-textarea
          v-model="form.description"
          auto-resize
          classes="tw-min-h-[280px] xs:tw-min-h-[230px] md:tw-min-h-[99%] tw-z-10 tw-relative tw-bg-transparent tw-resize-none"
          class="tw-h-full"
          @focus="textareaFocused = true"
          @blur="textareaFocused = false"
        >
          <template #content>
            <!-- EpUpload компонент для загрузки файла -->
            <div class="tw-flex tw-items-center tw-w-full tw-justify-between tw-px-4 tw-py-2 tw-absolute tw-bottom-1.5 tw-z-20">
              <div
                v-if="file"
                class="tw-flex tw-items-center tw-gap-1"
              >
                <span class="tw-text-base tw-font-medium tw-text-gray">{{ file.name }}</span>

                <div
                  class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-cursor-pointer"
                  @click="file = null"
                >
                  <ui-icon-base
                    :icon="icons['x-close']"
                    :width="10"
                    :height="10"
                    :view-box-size="[16, 16]"
                    :stroke-width="1"
                    stroke="#6C6D70"
                  />
                </div>
              </div>

              <ui-upload
                class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-cursor-pointer tw-ml-auto"
                @upload:file="file = $event"
              >
                <ui-icon-base
                  :icon="icons.attach"
                  :width="16"
                  :height="16"
                  :view-box-size="[14, 16]"
                  fill="#6C6D70"
                />
              </ui-upload>
            </div>

            <!-- Placeholder для textarea. Так как по макету placeholder представляет из себя текст и список -->
            <div
              v-if="!textareaFocused && !form.description.length"
              class="os-column-start tw-gap-3 tw-w-full tw-max-w-[90%] md:tw-max-w-full tw-top-[10px] tw-left-[14px] tw-absolute tw-z-0"
            >
              <span class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_title') }}</span>

              <ul class="tw-list-disc tw-list-inside tw-pl-2">
                <li class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_text_1') }}</li>
                <li class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_text_2') }}</li>
                <li class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_text_3') }}</li>
                <li class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_text_4') }}</li>
                <li class="tw-text-base tw-font-normal tw-text-gray">{{ t('inquiry_textarea_desc_text_5') }}</li>
              </ul>
            </div>
          </template>
        </ui-textarea>
      </div>
    </div>

    <ui-button
      class="ui-button_md ui-button_primary tw-ml-auto tw-w-full md:tw-w-auto"
      :value="t('create_inquiry')"
    />
  </form-validate>
</template>

<script>
import EpValidateInputHoc from '@/HOC/ep-validate-input.hoc'
import UiInput from '@mikael.tadesse/eparts-ui/src/package/components/ui-input/ui-input.vue'
import { Form } from 'vee-validate'

import icons from '@/utils/icons'
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { TOAST_TYPES } from '@/constants/toast'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'

export default {
  name: 'os-create-inquiry',
  components: {
    EpValidateInput: EpValidateInputHoc(UiInput),
    FormValidate: Form
  },
  setup () {
    const store = useStore()
    const toast = useToast()
    const { t } = useI18n()

    const file = ref(null)
    const textareaFocused = ref(false)

    const schema = Yup.object().shape({
      title: Yup.string(),
      fio: Yup.string().required(t('form_required_fio')),
      email: Yup.string().email().required(t('form_required_email')),
      phone: Yup.string().required(t('form_required_phone')),
      company: Yup.string(),
      description: Yup.string()
    })
    const form = reactive({
      title: '',
      fio: '',
      email: '',
      phone: '',
      company: '',
      description: ''
    })

    async function onCreateInquiry () {
      const formData = new FormData()

      for (const key in form) {
        formData.append(key, form[key])
      }

      formData.append('file', file.value)
      formData.append('articles', JSON.stringify([...store.state.CartModule.articles, ...store.state.CartModule.onRequestArticles]))

      await store.dispatch('CartModule/createInquiry', formData)
      toast.add({
        detail: {
          title: t('toast_title_create_inquiry'),
          text: 'We will contact you soon.',
          type: TOAST_TYPES.default
        }
      })
      clearForm()
    }

    function clearForm () {
      for (const key in form) {
        if (typeof form[key] === 'boolean') {
          form[key] = false
        } else {
          form[key] = ''
        }
      }

      file.value = null
    }

    return {
      file,
      form,
      icons,
      onCreateInquiry,
      schema,
      t,
      textareaFocused
    }
  }
}
</script>
