<template>
  <toast :pt="ptToast">
    <template #container="{ message, closeCallback }">
      <os-toast-cart
        v-if="message.detail.type === TOAST_TYPES.cart"
        :title="message.detail.title"
        :message="message.detail.text"
        @close="closeCallback"
      />

      <os-toast-default
        v-if="message.detail.type === TOAST_TYPES.default"
        :title="message.detail.title"
        :message="message.detail.text"
        @close="closeCallback"
      />
    </template>
  </toast>
</template>

<script>
import OsToastCart from './components/os-toast-cart.vue'
import OsToastDefault from './components/os-toast-default.vue'

import Toast from 'primevue/toast'
import { TOAST_TYPES } from '@/constants/toast'

import { ref } from 'vue'

export default {
  name: 'os-toast',
  components: {
    OsToastCart,
    OsToastDefault,
    Toast
  },
  setup () {
    const ptToast = {
      root: {
        class: 'os-toast tw-w-auto'
      },
      message: {
        class: 'os-column-start tw-gap-[38px] tw-pb-20'
      }
    }
    const isThereShadow = ref(false)

    return {
      isThereShadow,
      ptToast,
      TOAST_TYPES
    }
  }
}
</script>
