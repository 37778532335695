<template>
  <div class="tw-flex tw-items-center tw-w-auto">
    <div
      v-for="(item, index) in elements"
      :key="index"
      class="tw-flex tw-items-center tw-gap-2"
    >
      <tippy
        v-if="showHint"
        :duration="[150, 50]"
        theme="orange"
        content-class="os-tooltip"
      >
        <template #default>
          <ui-icon-base
            :icon="icons[statuses[item].icon]"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            class="5xl:tw-h-5 5xl:tw-w-5"
          />
        </template>

        <template #content>
          {{ statuses[item].label }}
        </template>
      </tippy>

      <ui-icon-base
        v-else
        :icon="icons[statuses[item].icon]"
        :width="15"
        :height="16"
        :view-box-size="[15, 16]"
        class="5xl:tw-h-5 5xl:tw-w-5"
      />

      <span
        v-if="showLabel"
        class="tw-text-sm md:tw-text-xs tw-font-normal tw-text-dark-gray 4xl:tw-text-[15px]"
      >
        {{ statuses[item].label }}
      </span>
    </div>
  </div>
</template>

<script>
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'os-article-status',
  components: {
    Tippy
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    showHint: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { t } = useI18n()
    const statuses = computed(() => ({
      haveOnStorage: {
        icon: 'in_stock',
        label: t('in_stock')
      },
      haveAnalogOnStorage: {
        icon: 'analogue_in_stock',
        label: t('analogue_in_stock')
      },
      inTransit: {
        icon: 'in_transit',
        label: t('in_transit')
      },
      isKit: {
        icon: 'assemble_article',
        label: t('assemble_article')
      },
      isAnalog: {
        icon: 'has_analogue',
        label: t('has_analogue')
      },
      salesDraw: {
        icon: 'draft',
        label: t('draft')
      }
    }))

    return {
      icons,
      statuses
    }
  }
}
</script>
