<template>
  <div class="os-main-wrapper">
    <os-header />

    <router-view>
      <template #defualt="{ Component }">
        <transition-fade>
          <component :is="Component" />
        </transition-fade>
      </template>
    </router-view>

    <modals-container />
    <os-scroll-to-top />
  </div>
</template>

<script>
import { ModalsContainer } from 'vue-final-modal'
import OsHeader from '@/modules/os-header/os-header.vue'
import OsScrollToTop from '@/modules/os-scroll-to-top/os-scroll-to-top.vue'
import { TransitionFade } from '@morev/vue-transitions/vue3'

export default {
  name: 'app',
  components: {
    ModalsContainer,
    OsHeader,
    OsScrollToTop,
    TransitionFade
  }
}
</script>
