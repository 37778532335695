<template>
  <div class="os-column-start tw-w-full tw-gap-4">
    <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ label }}</span>

    <ui-input
      v-model="query"
      name="search"
      :placeholder="t('ep_input_placeholder_search')"
      :model-value="''"
      class="tw-relative"
    >
      <template #icon-before>
        <div class="tw-flex tw-items-center tw-justify-center tw-h-full">
          <ui-icon-base
            :icon="icons['fi-rr-search']"
            :width="16"
            :height="16"
            :view-box-size="[16, 18]"
          />
        </div>
      </template>
    </ui-input>

    <div class="os-column-start tw-w-full tw-gap-3 tw-px-2">
      <transition-slide
        :group="true"
        :offset="['100%', '0']"
        class="os-column-start tw-w-full tw-gap-3 tw-px-2"
      >
        <label
          v-for="(item, index) in slicedElements"
          :key="index"
          class="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-medium tw-text-dark-gray tw-leading-[100%] tw-cursor-pointer"
        >
          <ui-checkbox
            v-if="isCheckbox"
            :value="selectedFilters[type].includes(item.name)"
            :classes="['ui-checkbox_base']"
            @update:value="$emit('update:selected-filter', { type, name: item.name })"
          />

          <ui-radio-button
            v-else
            :selected="radioButtonSelected"
            :value="item.name"
            :pt="{ input: { class: 'tw-rounded-full' } }"
            :merge-pt="true"
            @click="onUpdateRadioButton({ type, name: item.name })"
          />

          <span class="tw-text-sm tw-font-normal tw-text-dark-gray">{{ item.name }}</span>
        </label>
      </transition-slide>

      <span
        v-if="filteredCheckboxes.length > showElementsCount"
        class="tw-text-xs tw-font-semibold tw-text-orange tw-cursor-pointer"
        @click="++showMoreCount"
      >
        {{ t('show_more') }}
      </span>
    </div>
  </div>
</template>

<script>
import { TransitionSlide } from '@morev/vue-transitions/vue3'

import icons from '@/utils/icons'
import { computed, ref, toRefs, onMounted, onUpdated } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-filter-property',
  components: {
    TransitionSlide
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    isCheckbox: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    showElements: {
      type: Number,
      default: 5
    },
    showMore: {
      type: Number,
      default: 10
    },
    selectedFilters: {
      type: Object,
      required: true
    }
  },
  emits: ['update:selected-filter'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const { elements, isCheckbox } = toRefs(props)

    const query = ref('')
    const filteredCheckboxes = computed(() => {
      if (!elements.value.length) return []
      return elements.value.filter(item => item.name.toLowerCase().includes(query.value.toLowerCase()))
    })

    const showMoreCount = ref(0)
    const showElementsCount = computed(() => props.showElements + (showMoreCount.value * props.showMore))
    const slicedElements = computed(() => filteredCheckboxes.value.slice(0, showElementsCount.value))

    const activeCheckbox = computed(() => elements.value.find(item => item.active))
    const radioButtonSelected = ref('')

    onMounted(() => {
      updateSelectedRadioButton()
    })
    onUpdated(() => {
      updateSelectedRadioButton()
    })

    function updateSelectedRadioButton () {
      if (isCheckbox.value) {
        return
      }

      radioButtonSelected.value = activeCheckbox.value ? activeCheckbox.value.name : ''
    }

    function onUpdateRadioButton ({ type, name }) {
      if (radioButtonSelected.value) {
        emit('update:selected-filter', { type, name: radioButtonSelected.value })
      }

      radioButtonSelected.value = name
      emit('update:selected-filter', { type, name })
    }

    return {
      filteredCheckboxes,
      icons,
      query,
      onUpdateRadioButton,
      radioButtonSelected,
      showMoreCount,
      showElementsCount,
      slicedElements,
      t
    }
  }
}
</script>
