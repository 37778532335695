import axios from 'axios'
import { BASE_URL } from './config'

class Api {
  #client

  constructor () {
    this.#client = axios.create({ baseURL: BASE_URL })
  }

  async execute (method, url, { params = {}, data = {} }) {
    try {
      const result = await this.#client({
        method,
        url,
        params,
        data,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      return result?.data || {}
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error)
    }
  }

  get (url, { params = {} }) {
    return this.execute('get', url, { params })
  }

  post (url, { params = {}, data = {} }) {
    return this.execute('post', url, { params, data })
  }

  put (url, { params = {}, data = {} }) {
    return this.execute('put', url, { params, data })
  }

  delete (url, { data = {} }) {
    return this.execute('delete', url, { data })
  }
}

export default new Api()
