<template>
  <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-8 4xl:tw-gap-10 tw-px-4 md:tw-px-0">
    <router-link
      to="/"
      class="tw-flex tw-items-center tw-gap-2"
    >
      <ui-icon-base
        :icon="icons['fi-rr-angle-small-left']"
        :width="15"
        :height="16"
        :view-box-size="[15, 16]"
        fill="#6C6D70"
      />

      <span class="tw-text-sm tw-font-medium tw-text-gray tw-leading-[normal]">{{ t('back_to_product_list') }}</span>
    </router-link>

    <div
      v-if="items.length || itemsOnRequest.length"
      class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-8 4xl:tw-gap-10 tw-px-4 md:tw-px-0"
    >
      <os-article-status
        class="tw-flex-wrap tw-gap-4 lg:tw-gap-6 4xl:tw-gap-[30px]"
        :show-label="true"
        :elements="['isKit', 'isAnalog', 'haveOnStorage', 'haveAnalogOnStorage', 'inTransit', 'salesDraw']"
      />

      <div class="os-column-start tw-w-full tw-gap-5">
        <div
          v-if="breakpointGreaterOrEqualMd"
          class="tw-flex tw-items-center tw-justify-between tw-gap-6 tw-w-full"
        >
          <ui-button
            class="ui-button_md ui-button_outlined"
            @click="generateXlsxHandler"
          >
            <ui-icon-base
              :icon="icons['fi-rr-file-spreadsheet']"
              :width="15"
              :height="16"
              :view-box-size="[15, 16]"
            />

            <span class="tw-font-medium">{{ t('download_xlsx') }}</span>
          </ui-button>

          <div class="tw-flex tw-items-center tw-gap-6">
            <ui-input
              v-model="search"
              name="search"
              :placeholder="t('search_by_article_placeholder')"
              :model-value="''"
              class="tw-relative tw-w-[300px]"
              autocomplete="nope"
            >
              <template #icon-before>
                <div class="tw-flex tw-items-center tw-justify-center tw-h-full">
                  <ui-icon-base
                    :icon="icons['fi-rr-search']"
                    :width="16"
                    :height="16"
                    :view-box-size="[16, 16]"
                  />
                </div>
              </template>
            </ui-input>

            <label class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
              <ui-checkbox
                :value="offerPrice"
                :classes="['ui-checkbox_base']"
                @update:value="offerPrice = $event"
              />

              <span class="tw-text-sm tw-font-normal tw-text-dark-gray">{{ t('offer_your_price') }}</span>
            </label>
          </div>
        </div>

        <div
          v-if="breakpointSmallerMd"
          class="os-column-start tw-w-full tw-gap-8"
        >
          <div class="os-column-start tw-w-full tw-gap-4">
            <ui-input
              v-model="search"
              name="search"
              :placeholder="t('search_by_article_placeholder')"
              :model-value="''"
              class="tw-relative tw-w-full"
              autocomplete="nope"
            >
              <template #icon-before>
                <ui-icon-base
                  :icon="icons['fi-rr-search']"
                  :width="16"
                  :height="16"
                  :view-box-size="[16, 16]"
                />
              </template>
            </ui-input>

            <ui-button
              class="ui-button_md ui-button_outlined ui-button_w-full"
              @click="generateXlsxHandler"
            >
              <ui-icon-base
                :icon="icons['fi-rr-file-spreadsheet']"
                :width="15"
                :height="16"
                :view-box-size="[15, 16]"
                fill="#454545"
              />

              <span class="tw-font-medium">{{ t('download_xlsx') }}</span>
            </ui-button>

            <ui-button
              class="ui-button_md ui-button_primary ui-button_w-full"
              :value="t('go_to_inquiry')"
              @click="inquiry.$el.scrollIntoView({ behavior: 'smooth' })"
            />
          </div>

          <label class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
            <ui-checkbox
              :value="offerPrice"
              :classes="['ui-checkbox_base']"
              @update:value="offerPrice = $event"
            />

            <span class="tw-text-sm tw-font-normal tw-text-dark-gray">{{ t('offer_your_price') }}</span>
          </label>
        </div>

        <div class="os-column-start tw-gap-6 md:tw-gap-5 tw-w-full">
          <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-4">
            <h2
              v-if="itemsOnRequest.length"
              class="tw-text-[20px] tw-font-semibold tw-text-black"
            >
              {{ t('headline_in_stock') }}
            </h2>

            <div class="tw-w-full tw-overflow-x-auto">
              <os-table
                :items="filteredItems"
                :headers="filteredHeaders"
                :offer-price="offerPrice"
                @remove-article="removeArticle($event)"
                @update:article-by-property="updateArticleByProperty($event)"
                @update:qty="debounceUpdateQty"
              />
            </div>
          </div>

          <div
            v-if="itemsOnRequest.length"
            class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-4"
          >
            <h2 class="tw-text-[20px] tw-font-semibold tw-text-black">
              {{ t('headline_on_request') }}
            </h2>

            <div class="tw-w-full tw-overflow-x-auto">
              <os-table
                :items="itemsOnRequest"
                :headers="filteredHeaders"
                :offer-price="offerPrice"
                :is-transit="true"
                @remove-article="removeArticle({ ...$event, source: 'onRequestArticles' })"
                @update:article-by-property="updateArticleByProperty({ ...$event, source: 'onRequestArticles' })"
                @update:qty="updateArticleByProperty({ id: $event.item.id, source: 'onRequestArticles', property: 'qty', value: $event.value })"
              />
            </div>
          </div>

          <div class="tw-flex tw-items-center tw-w-full tw-justify-between">
            <ui-button
              class="ui-button_md ui-button_outlined tw-w-full md:tw-w-auto"
              @click="clearCart"
            >
              <ui-icon-base
                :icon="icons['fi-rr-cross-small']"
                :width="15"
                :height="16"
                :view-box-size="[15, 16]"
              />

              <span class="tw-font-medium">{{ t('clear_cart') }}</span>
            </ui-button>

            <ui-button
              v-if="breakpointGreaterOrEqualMd"
              class="ui-button_md ui-button_primary"
              :value="t('go_to_inquiry')"
              @click="inquiry.$el.scrollIntoView({ behavior: 'smooth' })"
            />
          </div>
        </div>
      </div>

      <os-create-inquiry ref="inquiry" />
      <os-toast />
    </div>

    <os-empty-result
      v-else
      :title="t('cart_empty_title')"
      :text="t('cart_empty_text')"
    />
  </div>
</template>

<script>
import OsArticleStatus from '@/components/os-article-status.vue'
import OsCreateInquiry from './components/os-create-inquiry.vue'
import OsEmptyResult from '@/components/os-empty-result.vue'
import OsTable from './components/os-table/os-table.vue'
import OsToast from '@/modules/os-toast/os-toast.vue'

// Utils
import icons from '@/utils/icons'
import GenerateXlsx from '@/classes/Generate-xlsx'
import { TOAST_TYPES } from '@/constants/toast'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { breakpointsTailwind, useBreakpoints, useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

export default {
  name: 'cart',
  components: {
    OsArticleStatus,
    OsCreateInquiry,
    OsEmptyResult,
    OsTable,
    OsToast
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const toast = useToast()

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterOrEqualMd = breakpoints.greaterOrEqual('md')
    const breakpointSmallerMd = breakpoints.smaller('md')

    const inquiry = ref(null)

    const search = ref('')
    const offerPrice = ref(false)

    const headerClasses = 'tw-flex tw-items-center tw-px-4 tw-min-h-[60px] tw-text-xs tw-font-medium tw-text-gray'
    const itemClasses = 'tw-flex tw-items-center tw-px-4 tw-min-h-[72px] tw-text-sm tw-font-normal tw-text-dark-gray'
    const headers = computed(() => [
      {
        column: 'oem',
        label: t('table_header_oem'),
        size: 'tw-w-[14%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'name',
        label: t('table_header_product_name'),
        size: 'tw-w-[14%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'status',
        label: t('table_header_product_status'),
        size: 'tw-w-[20%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'availability',
        label: t('availability'),
        size: 'tw-w-[16%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'lead_time',
        label: t('lead_time'),
        size: 'tw-w-[16%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'price',
        label: t('price'),
        size: 'tw-w-[10%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'offer-price',
        label: t('offer_price'),
        size: 'tw-w-[10%] tw-min-w-[70px]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'qty',
        label: t('qty'),
        size: 'tw-w-[10%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      },
      {
        column: 'actions',
        label: '',
        size: 'tw-w-[7%]',
        classesHeader: headerClasses,
        classesIcon: '',
        classesItem: itemClasses,
        icon: null,
        onClick: () => {}
      }
    ])

    const filteredHeaders = computed(() => headers.value.filter(item => offerPrice.value ? item : item.column !== 'offer-price'))
    const items = computed(() => store.state.CartModule.articles)
    const itemsOnRequest = computed(() => store.state.CartModule.onRequestArticles)
    const filteredItems = computed(() => {
      if (!items.value.length) return []

      return items.value.filter(item =>
        item.name.toLowerCase().includes(search.value.toLowerCase()) ||
        item.oem.toLowerCase().includes(search.value.toLowerCase()) ||
        !search.value.length)
    })

    onMounted(() => {
      store.dispatch('CartModule/initCart')
    })

    async function generateXlsxHandler () {
      const headers = [
        { key: 'oem', header: 'OEM Article', width: 30 },
        { key: 'name', header: 'Product name', width: 30 },
        { key: 'qty', header: 'Quantity', width: 30 }
      ]
      const xlsx = new GenerateXlsx(headers, items.value)

      xlsx.createSimpleSheet()
      await xlsx.generate()
    }

    function clearCart () {
      store.commit('CartModule/clearCart')
      toast.add({
        detail: {
          title: t('toast_title_delete_all_articles'),
          text: t('toast_text_delete_all_articles'),
          type: TOAST_TYPES.default
        }
      })
    }

    const onUpdateQty = ({ item, value }) => {
      const { availability } = item
      const totalAvailability = availability.reduce((sum, element) => sum + element.available, 0)

      if (value > totalAvailability) {
        store.commit('CartModule/updateRequestArticleQty', { article: item, value: value - totalAvailability })
      } else {
        store.commit('CartModule/updateArticleByProperty', { id: item.id, property: 'qty', value })
      }
    }
    const debounceUpdateQty = useDebounceFn((payload) => {
      onUpdateQty(payload)
    }, 300)

    return {
      breakpoints,
      breakpointGreaterOrEqualMd,
      breakpointSmallerMd,
      clearCart,
      debounceUpdateQty,
      filteredHeaders,
      filteredItems,
      generateXlsxHandler,
      icons,
      inquiry,
      items,
      itemsOnRequest,
      offerPrice,
      removeArticle: (index) => store.commit('CartModule/removeArticle', index),
      search,
      t,
      updateArticleByProperty: ({ id, property, source, value }) => store.commit('CartModule/updateArticleByProperty', { id, property, source, value })
    }
  }
}
</script>
