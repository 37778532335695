import { h, toRef } from 'vue'
import { useField } from 'vee-validate'

const EpValidateInputHoc = (component) => {
  return {
    setup (props, { emit, attrs, slots }) {
      const name = toRef(attrs, 'name')
      const {
        value,
        errorMessage,
        meta,
        handleChange
      } = useField(name.value, undefined, {
        initialValue: attrs.modelValue
      })

      function handleInput (event) {
        handleChange(event)
        emit('update:model-value', event)
      }

      const getSlots = Object.keys(slots).reduce((result, key) => {
        result[key] = () => slots[key]()
        return result
      }, {})

      return () => {
        return h(
          component,
          {
            modelValue: value,
            hint: errorMessage.value,
            error: meta.validated && !meta.valid,
            onBlur: (event) => handleChange(event),
            'onChange:model-value': (event) => handleInput(event),
            'onUpdate:model-value': (event) => emit('update:model-value', event)
          },
          getSlots
        )
      }
    }
  }
}

export default EpValidateInputHoc
