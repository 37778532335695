<template>
  <div
    v-show="isVisible"
    class="os-floating-button"
    @click="scrollToTop"
  >
    <ui-icon-base
      :icon="icons['arrow-top']"
      :width="16"
      :height="8"
      :view-box-size="[11, 8]"
      fill="#FFF"
    />
  </div>
</template>

<script>
import icons from '@/utils/icons'
import { ref, onMounted, onUnmounted } from 'vue'

/**
 * Модуль для отрисовки кнопки скролла к началу страницы
 * @displayName EpScrollToTop
 */
export default {
  name: 'os-scroll-to-top',
  setup () {
    const isVisible = ref(false)

    function onResize () {
      isVisible.value = window.scrollY >= (window.innerHeight * 0.8)
    }

    function scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    onMounted(() => document.addEventListener('scroll', onResize))
    onUnmounted(() => document.removeEventListener('scroll', onResize))

    return {
      icons,
      isVisible,
      scrollToTop
    }
  }
}
</script>
