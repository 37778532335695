<template>
  <div class="tw-flex tw-w-full tw-px-4 md:tw-px-0">
    <div
      class="tw-flex tw-w-full tw-h-[120px] sm:tw-h-[200px] 5xl:tw-h-[320px] min-[3000px]:tw-h-[400px] tw-bg-light-gray tw-rounded-[5px] tw-bg-cover 4xl:tw-bg-bottom 5xl:tw-bg-[left_28%_top] tw-transition-all tw-duration-250"
      :style="{
        backgroundImage: `url(${BASE_URL}/upload/osmi/${getBannerImg})`
      }"
    />
  </div>
</template>

<script>
import { BASE_URL } from '@/api/config'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { computed } from 'vue'

export default {
  name: 'os-banner',
  setup () {
    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      xs: '420px',
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px',
      '5xl': '2000px'
    })
    const getBannerImg = computed(() => {
      if (breakpoints.greaterOrEqual('5xl').value) {
        return 'banner_2xl.webp'
      } else if (breakpoints.greaterOrEqual('2xl').value) {
        return 'banner_xl.webp'
      } else if (breakpoints.greaterOrEqual('lg').value) {
        return 'banner_lg.webp'
      } else if (breakpoints.greaterOrEqual('xs').value) {
        return 'banner_md.webp'
      } else {
        return 'banner_sm.webp'
      }
    })

    return {
      BASE_URL,
      getBannerImg
    }
  }
}
</script>
