import { createRouter, createWebHashHistory } from 'vue-router'

import Cart from '@/views/Cart/Cart.vue'
import Home from '@/views/Home/Home.vue'

import changeTitle from './middlewares/change-title'
import loadMessages from './middlewares/load-messages'
import { routeMessages } from '@/constants/route-messages'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'osmi_home_meta_title',
      description: 'osmi_home_meta_description',
      message_keys: routeMessages.home
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      title: 'PAGE_TITLE_CART',
      description: '',
      message_keys: routeMessages.cart
    }
  },
  {
    path: '/:articleCode/',
    name: 'article',
    component: Home,
    meta: {
      title: '',
      description: '',
      message_keys: routeMessages.home
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.afterEach(changeTitle)
router.beforeEach(loadMessages)

export default router
