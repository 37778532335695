<template>
  <ui-table
    :class="['tw-flex', 'tw-flex-col', 'tw-w-full', 'tw-min-w-[1180px]']"
    :classes="['tw-border-b tw-border-middle-gray']"
    :headers="headers"
  >
    <template #header[price]="{ header: { label } }">
      <div class="tw-flex tw-items-center tw-gap-2">
        {{ label }}

        <tippy
          theme="orange"
          content-class="os-tooltip"
          :on-click-outside="instance => instance.hide()"
        >
          <template #default>
            <ui-icon-base
              :icon="icons['fi-rr-info']"
              :width="13"
              :height="12"
              :view-box-size="[13, 12]"
            />
          </template>

          <template #content>
            {{ t('price_hint') }}
          </template>
        </tippy>
      </div>
    </template>

    <template #header[qty]="{ header: { label } }">
      <div class="tw-flex tw-items-center tw-gap-2">
        {{ label }}

        <tippy
          theme="orange"
          content-class="os-tooltip"
          :on-click-outside="instance => instance.hide()"
        >
          <template #default>
            <ui-icon-base
              :icon="icons['fi-rr-info']"
              :width="13"
              :height="12"
              :view-box-size="[13, 12]"
            />
          </template>

          <template #content>
            {{ t('qty_hint') }}
          </template>
        </tippy>
      </div>
    </template>

    <template #default>
      <ui-row
        v-for="(row, index) in items"
        :key="index"
        :row="row"
      >
        <template #item[name]>
          <span
            class="tw-font-medium tw-text-orange tw-cursor-pointer"
            @click="openArticleModal(row.oem)"
          >
            {{ row.name }}
          </span>
        </template>

        <template #item[status]>
          <os-article-status
            :elements="row.status"
            :show-hint="true"
            class="tw-gap-3"
          />
        </template>

        <template #item[availability]>
          <ui-dropdown
            :options="row.availability"
            :model-value="row.availability[0]"
            :merge-pt="true"
            :pt="{ root: { class: 'tw-gap-2 tw-py-[10px] tw-px-[14px] sm:tw-py-[8px]' } }"
            :option-value="null"
            option-label="value"
          >
            <template #value="{ value }">
              {{ value.name }}: {{ value.available }}
            </template>

            <template #option="{ option }">
              {{ option.name }}: {{ option.available }}
            </template>
          </ui-dropdown>
        </template>

        <template #item[price]>
          <div class="os-column-start tw-gap-1">
            <span class="tw-text-xs tw-font-medium tw-text-dark-gray tw-line-through tw-whitespace-nowrap">{{ row.price.old }}</span>
            <span class="tw-text-sm tw-font-medium tw-text-orange tw-whitespace-nowrap">{{ row.price.new }}</span>
          </div>
        </template>

        <template #item[qty]>
          <os-quantity
            :count="row.qty"
            @increase="$emit('increase:qty', index)"
            @decrease="$emit('decrease:qty', index)"
          />
        </template>

        <template #item[actions]>
          <div class="tw-flex tw-items-center tw-justify-end tw-gap-6 tw-w-full">
            <ui-button
              class="ui-button_md ui-button_text"
              @click="$emit('add-to-cart', row)"
            >
              <ui-icon-base
                :icon="icons['fi-rr-shopping-cart-add']"
                :width="15"
                :height="16"
                :view-box-size="[15, 16]"
                fill="#EA5432"
                class="tw-cursor-pointer"
              />

              {{ t('add_to_cart') }}
            </ui-button>
          </div>
        </template>
      </ui-row>
    </template>
  </ui-table>
</template>

<script>
import OsArticleModal from '@/modals/os-article-modal/os-article-modal.vue'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsQuantity from '@/components/os-quantity.vue'
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-table-desktop',
  components: {
    OsArticleStatus,
    OsQuantity,
    Tippy
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { t } = useI18n()

    function openArticleModal (oem) {
      const { open } = useModal({
        component: OsArticleModal,
        attrs: {
          oem
        }
      })
      open()
    }

    return {
      icons,
      openArticleModal,
      t
    }
  }
}
</script>
