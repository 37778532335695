<template>
  <div class="os-column-start tw-w-full tw-gap-6">
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="os-column-start tw-w-full tw-border tw-border-middle-gray tw-rounded-[5px]"
    >
      <div class="os-column-start tw-w-full tw-p-4 tw-gap-4">
        <div class="os-column-start tw-py-2 tw-w-full tw-gap-3">
          <span
            class="tw-text-base tw-font-medium tw-text-gray tw-cursor-pointer"
            @click="openArticleModal(item.oem)"
          >
            {{ item.name }}
          </span>

          <span class="tw-text-base tw-font-medium tw-text-orange">{{ item.brand }}</span>
        </div>

        <div class="os-column-start tw-py-2 tw-w-full tw-gap-3">
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('table_header_oem') }}</span>
          <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ item.oem }}</span>
        </div>

        <div class="os-column-start tw-py-2 tw-w-full tw-gap-3">
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('table_header_product_status') }}</span>

          <os-article-status
            :elements="item.status"
            :show-hint="true"
            class="tw-gap-3"
          />
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-py-2 tw-w-full tw-gap-4">
          <div class="os-column-start tw-w-full tw-gap-3">
            <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('availability') }}</span>

            <ui-dropdown
              v-if="!isTransit"
              :model-value="item.availability[0]"
              :options="item.availability"
              :pt="{ root: { class: 'tw-gap-2 tw-py-[10px] tw-px-[14px] sm:tw-py-[8px]' } }"
              :merge-pt="true"
            >
              <template #value="{ value }">
                {{ value.name }}: {{ value.available }}
              </template>

              <template #option="{ option }">
                {{ option.name }}: {{ option.available }}
              </template>
            </ui-dropdown>
          </div>

          <div class="os-column-start tw-w-full tw-gap-3">
            <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('lead_time') }}</span>
            <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ item.lead_time }}</span>
          </div>
        </div>

        <div
          v-if="offerPrice"
          class="os-column-start tw-py-2 tw-w-full tw-gap-3"
        >
          <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('offer_price') }}</span>

          <ui-input
            :model-value="item.offer_price"
            name="offer-price"
            :placeholder="t('price')"
            class="tw-w-[90px]"
            @update:model-value="$emit('update:article-by-property', { id: item.id, property: 'offer_price', value: $event })"
          />
        </div>

        <div class="tw-grid tw-grid-cols-2 tw-py-2 tw-w-full tw-gap-4">
          <div class="os-column-start tw-w-full tw-gap-3">
            <div class="tw-flex tw-items-center tw-gap-2">
              <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('price') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('price_hint') }}
                </template>
              </tippy>
            </div>

            <span
              v-if="item.price.new"
              class="tw-text-base tw-font-medium tw-text-dark-gray"
            >
              {{ item.price.new }}
            </span>

            <span
              v-else
              class="tw-text-base tw-font-medium tw-text-dark-gray"
            >
              {{ item.price.old }}
            </span>
          </div>

          <div class="os-column-start tw-w-full tw-gap-3">
            <div class="tw-flex tw-items-center tw-gap-2">
              <span class="tw-text-base tw-font-medium tw-text-gray">{{ t('qty') }}</span>

              <tippy
                theme="orange"
                content-class="os-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <ui-icon-base
                    :icon="icons['fi-rr-info']"
                    :width="13"
                    :height="12"
                    :view-box-size="[13, 12]"
                  />
                </template>

                <template #content>
                  {{ t('qty_hint') }}
                </template>
              </tippy>
            </div>

            <os-quantity
              :count="item.qty"
              @increase="$emit('update:qty', { item: item, value: item.qty + 1 })"
              @decrease="$emit('update:qty', { item: item, value: item.qty - 1 })"
            />
          </div>
        </div>
      </div>

      <div class="tw-flex tw-py-6 tw-px-4 tw-w-full tw-border-t tw-border-middle-gray">
        <ui-button
          class="ui-button_md ui-button_outlined ui-button_w-full"
          @click="$emit('remove:article', index)"
        >
          <ui-icon-base
            :icon="icons['x-close']"
            :width="16"
            :height="16"
            :view-box-size="[16, 16]"
            :stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke="#EA5432"
          />

          <span class="tw-font-normal tw-text-dark-gray">{{ t('remove') }}</span>
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import OsArticleModal from '@/modals/os-article-modal/os-article-modal.vue'
import OsArticleStatus from '@/components/os-article-status.vue'
import OsQuantity from '@/components/os-quantity.vue'
import { Tippy } from 'vue-tippy'

import icons from '@/utils/icons'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

export default {
  name: 'os-table-mobile',
  components: {
    OsArticleStatus,
    OsQuantity,
    Tippy
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isTransit: {
      type: Boolean,
      default: false
    },
    offerPrice: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove:article', 'update:qty', 'update:article-by-property'],
  setup () {
    const { t } = useI18n()

    function openArticleModal (oem) {
      const { open } = useModal({
        component: OsArticleModal,
        attrs: {
          oem
        }
      })
      open()
    }

    return {
      icons,
      openArticleModal,
      t
    }
  }
}
</script>
