import { createStore } from 'vuex'

// Modules
import CartModule from './modules/cart.module'
import LocalizationModule from './modules/localization.module'
import OsmiModule from './modules/osmi.module'

// Plugins
import { cacheCartPlugin } from './plugins/cache-cart.plugin'

export default createStore({
  state: {
    currencies: [
      {
        name: 'rub',
        icon: {
          name: 'rub',
          width: 21,
          height: 20,
          viewBoxSize: [21, 20],
          fill: '#454545'
        }
      },
      {
        name: 'usd',
        icon: {
          name: 'usd',
          width: 21,
          height: 20,
          viewBoxSize: [21, 20],
          fill: '#454545'
        }
      },
      {
        name: 'eur',
        icon: {
          name: 'eur',
          width: 21,
          height: 20,
          viewBoxSize: [21, 20],
          fill: '#454545'
        }
      },
      {
        name: 'cny',
        icon: {
          name: 'cny',
          width: 21,
          height: 20,
          viewBoxSize: [21, 20],
          fill: '#454545'
        }
      }
    ],
    selectedCurrency: null
  },
  mutations: {
    /**
     * @param { state } state
     * @param { string } currency
     */
    setCurrency (state, currency) {
      state.selectedCurrency = currency
    }
  },
  modules: {
    CartModule,
    LocalizationModule,
    OsmiModule
  },
  plugins: [cacheCartPlugin]
})
