export default {
  rub: [
    'M11.5132 5H9.32574C8.95074 5 8.70074 5.25 8.70074 5.625V9.375H7.45074C7.07574 9.375 6.82574 9.625 6.82574 10C6.82574 10.3125 7.07574 10.625 7.45074 10.625H8.70074V11.875H7.45074C7.07574 11.875 6.82574 12.1875 6.82574 12.5C6.82574 12.8125 7.07574 13.125 7.45074 13.125H8.70074V14.375C8.70074 14.6875 8.95074 15 9.32574 15C9.70074 15 9.95074 14.6875 9.95074 14.375V13.125H11.8257C12.1382 13.125 12.4507 12.8125 12.4507 12.5C12.4507 12.1875 12.1382 11.875 11.8257 11.875H9.95074V10.625H11.5132C13.0757 10.625 14.3257 9.375 14.3257 7.8125C14.3257 6.25 13.0757 5 11.5132 5ZM11.5132 9.375H9.95074V6.25H11.5132C12.3882 6.25 13.0757 6.9375 13.0757 7.8125C13.0757 8.6875 12.3882 9.375 11.5132 9.375Z',
    'M10.5757 0.625C5.38824 0.625 1.20074 4.8125 1.20074 10C1.20074 15.1875 5.38824 19.375 10.5757 19.375C15.7632 19.375 19.9507 15.1875 19.9507 10C19.9507 4.8125 15.7632 0.625 10.5757 0.625ZM10.5757 18.125C6.07574 18.125 2.45074 14.5 2.45074 10C2.45074 5.5 6.07574 1.875 10.5757 1.875C15.0757 1.875 18.7007 5.5 18.7007 10C18.7007 14.5 15.0757 18.125 10.5757 18.125Z'
  ],
  usd: [
    'M10.5757 0.625C5.38824 0.625 1.20074 4.8125 1.20074 10C1.20074 15.1875 5.38824 19.375 10.5757 19.375C15.7632 19.375 19.9507 15.1875 19.9507 10C19.9507 4.8125 15.7632 0.625 10.5757 0.625ZM10.5757 18.125C6.07574 18.125 2.45074 14.5 2.45074 10C2.45074 5.5 6.07574 1.875 10.5757 1.875C15.0757 1.875 18.7007 5.5 18.7007 10C18.7007 14.5 15.0757 18.125 10.5757 18.125Z',
    'M12.4507 7.8125C12.4507 8.1875 12.7632 8.4375 13.0757 8.4375C13.3882 8.4375 13.7007 8.1875 13.7007 7.8125C13.7007 6.4375 12.6382 5.3125 11.2007 5.0625V4.375C11.2007 4 10.8882 3.75 10.5757 3.75C10.2632 3.75 9.95074 4 9.95074 4.375V5.0625C8.51324 5.3125 7.45074 6.4375 7.45074 7.8125C7.45074 9.1875 8.51324 10.3125 9.95074 10.5625V13.625C9.20074 13.375 8.70074 12.8125 8.70074 12.125C8.70074 11.8125 8.45074 11.5 8.07574 11.5C7.70074 11.5 7.45074 11.8125 7.45074 12.125C7.45074 13.5 8.51324 14.625 9.95074 14.875V15.625C9.95074 15.9375 10.2007 16.25 10.5757 16.25C10.8882 16.25 11.2007 15.9375 11.2007 15.625V14.9375C12.6382 14.6875 13.7007 13.5625 13.7007 12.1875C13.7007 10.8125 12.6382 9.6875 11.2007 9.4375V6.375C11.9507 6.5625 12.4507 7.125 12.4507 7.8125ZM8.70074 7.8125C8.70074 7.125 9.20074 6.5625 9.95074 6.3125V9.25C9.20074 9.0625 8.70074 8.5 8.70074 7.8125ZM12.4507 12.1875C12.4507 12.875 11.9507 13.4375 11.2007 13.6875V10.75C11.9507 10.9375 12.4507 11.5 12.4507 12.1875Z'
  ],
  eur: [
    'M10.5757 0.625C5.38824 0.625 1.20074 4.8125 1.20074 10C1.20074 15.1875 5.38824 19.375 10.5757 19.375C15.7632 19.375 19.9507 15.1875 19.9507 10C19.9507 4.8125 15.7632 0.625 10.5757 0.625ZM10.5757 18.125C6.07574 18.125 2.45074 14.5 2.45074 10C2.45074 5.5 6.07574 1.875 10.5757 1.875C15.0757 1.875 18.7007 5.5 18.7007 10C18.7007 14.5 15.0757 18.125 10.5757 18.125Z',
    'M11.1382 6.25C11.9507 6.25 12.7007 6.625 13.2632 7.3125C13.5132 7.5625 13.8882 7.625 14.1382 7.375C14.3882 7.125 14.4507 6.75 14.2007 6.5C13.3882 5.5625 12.2632 5 11.1382 5C9.38824 5 7.82574 6.25 7.13824 8.125H6.20074C5.82574 8.125 5.57574 8.375 5.57574 8.75C5.57574 9.125 5.82574 9.375 6.20074 9.375H6.88824C6.88824 9.5625 6.82574 9.8125 6.82574 10C6.82574 10.1875 6.82574 10.4375 6.88824 10.625H6.20074C5.82574 10.625 5.57574 10.9375 5.57574 11.25C5.57574 11.5625 5.82574 11.875 6.20074 11.875H7.13824C7.76324 13.75 9.32574 15 11.1382 15C12.3257 15 13.3882 14.4375 14.2007 13.5C14.4507 13.25 14.3882 12.875 14.1382 12.625C13.8882 12.375 13.5132 12.4375 13.2632 12.6875C12.7007 13.375 11.9507 13.75 11.1382 13.75C10.0132 13.75 9.07574 13 8.51324 11.875H11.2007C11.5132 11.875 11.8257 11.5625 11.8257 11.25C11.8257 10.9375 11.5132 10.625 11.2007 10.625H8.13824C8.07574 10.4375 8.07574 10.1875 8.07574 10C8.07574 9.8125 8.07574 9.5625 8.13824 9.375H11.2007C11.5132 9.375 11.8257 9.125 11.8257 8.75C11.8257 8.375 11.5132 8.125 11.2007 8.125H8.51324C9.01324 7 10.0132 6.25 11.1382 6.25Z'
  ],
  cny: [
    'M10.5757 0.625C5.38824 0.625 1.20074 4.8125 1.20074 10C1.20074 15.1875 5.38824 19.375 10.5757 19.375C15.7632 19.375 19.9507 15.1875 19.9507 10C19.9507 4.8125 15.7632 0.625 10.5757 0.625ZM10.5757 18.125C6.07574 18.125 2.45074 14.5 2.45074 10C2.45074 5.5 6.07574 1.875 10.5757 1.875C15.0757 1.875 18.7007 5.5 18.7007 10C18.7007 14.5 15.0757 18.125 10.5757 18.125Z',
    'M13.7007 9.99997C14.0132 9.99997 14.3257 9.74997 14.3257 9.37497C14.3257 8.99997 14.0132 8.74997 13.7007 8.74997H11.8882L14.1382 5.99997C14.3882 5.74997 14.3257 5.31247 14.0757 5.12497C13.8257 4.93747 13.4507 4.93747 13.2007 5.18747L10.5757 8.37497L7.95074 5.18747C7.70074 4.93747 7.32574 4.87497 7.07574 5.12497C6.82574 5.37497 6.76324 5.74997 7.01324 5.99997L9.26324 8.74997H7.45074C7.07574 8.74997 6.82574 8.99997 6.82574 9.37497C6.82574 9.74997 7.07574 9.99997 7.45074 9.99997H9.95074V10.625H7.45074C7.07574 10.625 6.82574 10.9375 6.82574 11.25C6.82574 11.5625 7.07574 11.875 7.45074 11.875H9.95074V14.375C9.95074 14.6875 10.2007 15 10.5757 15C10.8882 15 11.2007 14.6875 11.2007 14.375V11.875H13.7007C14.0132 11.875 14.3257 11.5625 14.3257 11.25C14.3257 10.9375 14.0132 10.625 13.7007 10.625H11.2007V9.99997H13.7007Z'
  ]
}
